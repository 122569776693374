import { AppHeader } from './components/Layout';
import { LoginPage } from './components/LoginForm';
import { userTokenAtom } from './atoms';
import { useAtom } from 'jotai';
import UserHome from './components/User/UserHome';

export default function Home() {
  const [userToken, setUserToken] = useAtom(userTokenAtom);

  return (
    <>
      <AppHeader />
      {userToken ? <UserHome /> : <LoginPage />}
    </>
  );
}
