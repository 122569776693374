import paddle from '../assets/paddle.png';

function PlayerControls({
  server,
  playerName,
  unforcedErrorHandler,
  errorHandler,
  winnerHandler
}) {
  return (
    <div className="mr-1 font-bold flex-col w-1/2">
      <div className="p-1 inline-flex bg-gray-100 border rounded w-full text-center items-center justify-center">
        {playerName}
        {/* {(server === playerName) && <img src={paddle} className="ml-2 h-4 w-4" />} */}
      </div>
      <div className="flex rounded-md shadow-sm w-full" role="group">
        <button
          type="button"
          className="grow py-1 px-2 text-xs font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          onClick={unforcedErrorHandler}
        >
          Unforced Error
        </button>
        <button
          type="button"
          className=" py-1 px-2 text-xs font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          onClick={errorHandler}
        >
          Error
        </button>
        <button
          type="button"
          className=" py-1 px-2 text-xs font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          onClick={winnerHandler}
        >
          Winner
        </button>
      </div>
    </div>
  )
}

export default PlayerControls;
