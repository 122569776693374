import RallyRow from "./RallyRow"
import { Tooltip } from "@material-tailwind/react";

const RallyLog = ({
  rallies,
  youtubePlayer,
  clicks,
  updateRally,
  updateShot,
  player1,
  player2,
  player3,
  player4,
}) => {
  return (
    <>
      <div className="mr-4">
        {/* <h3 className="text-lg font-bold mb-1">Rally Log</h3> */}
        <div className="mb-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th></th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Pt Number</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Score</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Start</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">End</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Pt Outcome</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Srv Team</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Rally Len</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Serve Stack</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Ret Stack</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900 group">
                    3rd Shot Player Side
                  <p className="break-words w-96 tooltip-text hidden text-center py-2 px-6 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">Unlike the 1/2 hotkeys, this value should reflect the side of the third shot play from the perspective of the player</p>
                </th>
                {/* <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">
                  3rd Shot Player
                </th> */}
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">3rd Shot Type</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Ending Player</th>
                <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Ending Type</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {rallies.map((rally) =>
                <RallyRow
                  key={`rally-row-${rally.thisPointNumber}${rally.pointOutcome}`}
                  youtubePlayer={youtubePlayer}
                  rally={rally}
                  clicks={clicks}
                  updateRally={updateRally}
                  updateShot={updateShot}
                  players={["N/A", player1.name, player2.name, player3.name, player4.name]}
                />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default RallyLog;
