import { useAtom } from 'jotai';
import {
  pointNumberAtom,
  servingTeamAtom,
  player1Atom,
  player2Atom,
  player3Atom,
  player4Atom,
  shotTypesAtom
} from './../atoms';

function CurrentShotLog({
  clicks,
  updateShot,
  youtubePlayer
}) {
  const [player1, setPlayer1] = useAtom(player1Atom);
  const [player2, setPlayer2] = useAtom(player2Atom);
  const [player3, setPlayer3] = useAtom(player3Atom);
  const [player4, setPlayer4] = useAtom(player4Atom);
  const [pointNumber, setPointNumber] = useAtom(pointNumberAtom);
  const [servingTeam, setServingTeam] = useAtom(servingTeamAtom);
  const [shotTypes, setShotTypes] = useAtom(shotTypesAtom);

  let playersArr = ["N/A", player1.name, player2.name, player3.name, player4.name];


  return (
    <>
    <div id="current-point-shots">
      <div className="mb-8 overflow-hiddenshadow ring-1 ring-black ring-opacity-5">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Pt</th>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Shot</th>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Player</th>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Shot Type</th>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Time</th>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">X</th>
              <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Y</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {clicks
              .filter(click => click.pointNumber === pointNumber)
              .map(
                ({
                  pointNumber, shotNumber, shotPlayer, shotType, shotSec, videoTimestamp, time, x, y
                }) =>
                  {
                    if (servingTeam === "A") {
                      if (shotNumber % 2 !== 0) {
                        playersArr = [player1.name, player2.name]
                      } else {
                        playersArr = [player3.name, player4.name]
                      }
                    } else {
                      if (shotNumber % 2 !== 0) {
                        playersArr = [player3.name, player4.name]
                      } else {
                        playersArr = [player1.name, player2.name]
                      }
                    }
                    let currentShotTypes = [];
                    if (shotNumber === 3) {
                      currentShotTypes = Object.entries(shotTypes).filter(([shotName, shotvalue], _) => shotName.includes("3rd"))
                    } else if (shotNumber > 3) {
                      currentShotTypes = Object.entries(shotTypes).filter(([shotName, shotvalue], _) => [
                        "Speed Up", "Lob", "ATP", "Ernie", "Dink", "Other", "Hand Battle", "Transition Zone - Approach", "Transition Zone - Repel", "Reset", "Unknown", "N/A"
                      ].includes(shotName)) }
                    else {
                      currentShotTypes = Object.entries(shotTypes);
                    }
                    return <tr key={`shot-log-${pointNumber}${shotNumber}`}>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{pointNumber}</td>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{shotNumber}</td>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500 group">
                        <select
                          className="px-1 w-24"
                          value={shotPlayer}
                          onChange={updateShot(pointNumber, shotNumber, "shotPlayer")}
                        >
                          <option value=""></option>
                          {playersArr.map(player =>
                            <option key={`click-shot-log-${player}-${pointNumber}-${shotNumber}`} value={player}>
                              {player}
                            </option>)}
                        </select>
                        <p className="break-words tooltip-text hidden text-center py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          If left empty our machine learning algorithm will infer who<br/>
                          hit the shot with a high level of accuracy based on shot<br/>
                          location, direction, player handedness, and other key factors.<br/>
                          Accuracy drops in cases of extreme poaching, or if players<br/>
                          switch sides mid-rally (for a reason other than stacking).
                        </p>
                      </td>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500 group">
                        <select
                          className="px-1 w-16"
                          value={shotType}
                          onChange={updateShot(pointNumber, shotNumber, "shotType")}
                          disabled={shotNumber === 1 || shotNumber === 2}
                        >
                          <option value=""></option>
                          {currentShotTypes.map(([shotName, shotvalue], _) =>
                            <option key={`shot-log-player-${shotvalue}-${shotNumber}-${pointNumber}`} value={shotvalue}>
                              {shotName}
                            </option>)}
                        </select>
                        <p className="break-words tooltip-text hidden py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">If left empty or set to "Other", <br/> our machine learning algorithm<br/> will infer the shot type based<br/> on ball location, previous shot <br/> information, and time between<br/>shots.</p>
                      </td>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-blue-500 font-medium"><button onClick={() => youtubePlayer?.seekTo(shotSec, 'seconds')}>{videoTimestamp}</button></td>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{x}</td>
                      <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{y}</td>
                    </tr>
                  }
              )}
          </tbody>
        </table>
      </div>
    </div>
    </>
  )
}

export default CurrentShotLog;
