import { useRef, useEffect, useState } from 'react';
import classNames from "classnames";
import { useLocation } from "wouter";

import volley from './../../assets/volley.png';
import dink from './../../assets/dink.png';
import trans from './../../assets/trans.png';
import serve from './../../assets/serve.png';
import drop from './../../assets/drop.png';
import returnImage from './../../assets/return.png';
import repel from './../../assets/repel.png';
import drive from './../../assets/drive.png';

export default function UnreturnedRates({
  playerId,
  overAllUnreturnedPercent,
  serveUnreturnedPercent,
  returnUnreturnedPercent,
  thirdShotDriveUnreturnedPercent,
  thirdShotDropUnreturnedPercent,
  transitionZoneApproachUnreturnedPercent,
  transitionRepelUnreturnedPercent,
  dinkUnreturnedPercent,
  spUnreturnedPercent,
  unreturnedRates,
}) {
  const [location, setLocation] = useLocation();
  const [benchmark, setBenchmark] = useState("3.5");

  const comps = unreturnedRates.find(rates => rates.id == benchmark)

  const errorRateWord = (comparedTo, comparePercent) => {
    const percent = ((comparePercent - comparedTo) * 100).toFixed(2)
    return <>
      <span>
        &nbsp;<span className={classNames({"text-red-600": percent < 0, "text-[#18BC9C]": percent > 0})}>{percent < 0 ? "Worse" : "Better"}</span> than other a typical&nbsp;
        {benchmark}: <span className={classNames({"text-red-600": percent < 0, "text-[#18BC9C]": percent > 0})}>{(comparedTo * 100).toFixed(2)}%</span>
      </span>
    </>
  }

  return (
    <>
      <div>
        <div className="text-center my-4">
          Rating benchmark
          <span className="isolate inline-flex rounded-md shadow-sm ml-4">
            <button type="button" className={classNames("relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "3.0", "bg-white hover:bg-gray-50": benchmark !== "3.0" })} onClick={() => setBenchmark("3.0")}>3.0</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "3.5", "bg-white hover:bg-gray-50": benchmark !== "3.5" })} onClick={() => setBenchmark("3.5")}>3.5</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "4.0", "bg-white hover:bg-gray-50": benchmark !== "4.0" })} onClick={() => setBenchmark("4.0")}>4.0</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "4.5", "bg-white hover:bg-gray-50": benchmark !== "4.5" })} onClick={() => setBenchmark("4.5")}>4.5</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "5.0", "bg-white hover:bg-gray-50": benchmark !== "5.0" })} onClick={() => setBenchmark("5.0")}>5.0</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "Pro", "bg-white hover:bg-gray-50": benchmark !== "Pro" })} onClick={() => setBenchmark("Pro")}>Pro</button>
          </span>
        </div>
        <div className="grid lg:grid-cols-3 text-center gap-16 p-4">
          <div>
            <p className="font-semibold text-lg">Overall Unreturned Rate</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-pkl-500 h-20 mx-auto">
              <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
            </svg>
            <p className="text-6xl font-semibold">{(overAllUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.overall_unreturned_pct, overAllUnreturnedPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Serve</p>
            <img className="" src={serve} />
            <p className="text-6xl font-semibold">{(serveUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.srv_unreturned_pct, serveUnreturnedPercent)}
          </div>
          <div>
            <p
              className="font-semibold text-lg cursor-pointer hover:text-pkl-500"
              onClick={() => setLocation(`/players/${playerId}/returns`)}
            >
              Return
            </p>
            <img className="" src={returnImage} />
            <p className="text-6xl font-semibold">{(returnUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.rtrn_unreturned_pct, returnUnreturnedPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">3rd Shot Drop</p>
            <img className="" src={drop} />
            <p className="text-6xl font-semibold">{(thirdShotDropUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.ts_drp_unreturned_pct, thirdShotDropUnreturnedPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">3rd Shot Drive</p>
            <img className="" src={drive} />
            <p className="text-6xl font-semibold">{(thirdShotDriveUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.ts_drv_unreturned_pct, thirdShotDriveUnreturnedPercent)}
          </div>
          <div className="">
            <p className="font-semibold text-lg">Transition Approach</p>
            <img className="" src={trans} />
            <p className="text-6xl font-semibold">{(transitionZoneApproachUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.tz_appr_unreturned_pct, transitionZoneApproachUnreturnedPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Transition Repel</p>
            <img className="" src={repel} />
            <p className="text-6xl font-semibold">{(transitionRepelUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.tz_repel_unreturned_pct, transitionRepelUnreturnedPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Dink</p>
            <img className="" src={dink} />
            <p className="text-6xl font-semibold">{(dinkUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.dink_unreturned_pct, dinkUnreturnedPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Speed Up</p>
            <img className="" src={volley} />
            <p className="text-6xl font-semibold">{(spUnreturnedPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.sp_unreturned_pct, spUnreturnedPercent)}
          </div>
        </div>
        <div className="text-center">
          <h4 className="font-semibold text-2xl mt-8 mb-4">Road To Leveling Up!</h4>
          <p className="text-xl">Want to win more matches? Based on your error rates compared to similar players, you could improve the most by working on these shots!</p>
        </div>
      </div>
    </>
  )
}
