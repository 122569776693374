import { useRef, useEffect, useState } from 'react';
import logo from './../assets/pklmart-logo.png';

import classNames from 'classnames';
import YouTube from 'react-youtube';
import Guide from './Guide';

function IntroModal({
  displayIntroModal,
  setDisplayIntroModal
}) {
  const [displayVideo, setDisplayVideo] = useState(false);
  const [displayText, setDisplayText] = useState(true);
  const [introVideo, setIntroVideo] = useState(null);

  return (
    <>
      <div
        className={classNames("relative z-10", {
          "hidden": !displayIntroModal
        })}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {/*
              Modal panel, show/hide based on modal state.

              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            */}
            <div className="relative transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-xl transition-all w-3/5">
              <div>
                <div className="mt-3 sm:mt-5">
                  <div className="text-center">
                    <div className="inline-flex items-center">
                      <div className="mr-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <img src={logo} />
                      </div>
                      <h3 className="text-4xl text-center font-bold text-gray-900" id="modal-title">Welcome to the pklmart Data Entry Tool!</h3>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="text-gray-500 text-lg text-center">If this is your first time here, please choose your instruction style.</p>
                    <div className="inline-flex rounded-md shadow-sm my-4" role="group">
                      <button
                        type="button"
                        className={classNames("inline-flex items-center px-4 py-2 font-medium border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white", {
                          "bg-gray-900 text-white": displayText,
                          "text-gray-900 bg-transparent": !displayText
                      })}
                        onClick={() => {
                          setDisplayText(true)
                          setDisplayVideo(false)
                        }}
                      >
                        <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        Text
                      </button>
                      <button
                        type="button"
                        className={classNames("inline-flex items-center px-4 py-2 font-medium border border-gray-900 rounded-r-md hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white", {
                          "bg-gray-900 text-white": displayVideo,
                          "text-gray-900 bg-transparent": !displayVideo
                        })}
                        onClick={() => {
                          setDisplayText(false)
                          setDisplayVideo(true)
                        }}
                      >
                      <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                      </svg>
                        Video
                      </button>
                    </div>
                  </div>
                  <div className={classNames({
                    "hidden": !displayVideo
                  })}>
                    <YouTube
                      videoId="KZg7CHC0QtM"
                      opts={{
                        width: "100%",
                        height: "600px"
                      }}
                      onReady={(event) => {
                        setIntroVideo(event.target)
                      }}
                    />
                  </div>
                  <div className={classNames("h-[50vh] overflow-y-auto", {
                    "hidden": !displayText
                  })}>
                    <div>
                      <span className="font-bold block">1. Identify a video you would like to collect data on.</span>
                      <p className="text-sm">Ideally the video keeps a consistent camera angle to make data entry easier. Looking to analyze pro players? Most APP/PPA videos work well. However, some of those videos utilize a kitchen camera which can be disorienting.</p>
                      <span className="font-bold block leading-5 mt-2">2. Input game info</span>
                      <p className="text-sm">Fill in the corresponding fields so we can correctly classify the game. Fields with * are required fields, please make sure to fill these in!</p>
                      <span className="font-bold block leading-5 mt-2">3. Great! Now are you ready to begin recording a rally. While the video plays, click the court icon in the location, and at the same time as when the ball is struck.</span>
                      <p className="text-sm">The timestamp of each click and the location of each click are used to identify what kind of shot is being hit. The accurate the data collected, the more accurate the results will be!</p>
                      <br />
                      <p className="text-sm">Here are the mouse clicks and keystrokes you will need to input:</p>
                      <br />
                      <Guide />
                      <div>
                      <p className="font-bold mt-4">4. At the end of the rally, the user can click one additional time if an error or winner was hit to indicate where the error or winner landed. This data is helpful for diagnosing potential issues with your shot selection.</p>
                      <p className="font-bold mt-4">5. Enter the rally by selecting one of the rally outcomes. If a player ended the rally with a winner or error, select that option!</p>
                      <p className="font-bold mt-4">6. Once you have finished the game, hit “Complete game and download CSVs”.</p>
                      <p>This will produce three .CSV files containing all the data you entered. Files will be located in the same folder where you launched the program from.</p>
                      <p className="mt-2">While you can analyze the output yourself, please send over the CSV output to be loaded into the pklmart! From here an auto generated report will be created. Additional visuals and metrics can be produced upon request.</p>
                      <p className="mt-2"><a className="text-blue-400 font-bold" href="mailto:pklmart.analytics@gmail.com">pklmart.analytics@gmail.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-lg font-bold"
                  onClick={() => {
                    setDisplayIntroModal(false)
                    introVideo.stopVideo();
                  }}
                >
                  Get started!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IntroModal
