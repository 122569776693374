import logo from './../assets/pklmart-logo.png';

function Header() {
  return (
    <header className="p-2 border-b-2" id="det-header">
      <a className="flex items-center justify-center" href="https://pklmart.com/reports">
        <img
          src={logo}
          className="h-8"
        />
        <h1 className="text-2xl ml-4 font-bold" id="logo">pklmart Data Entry Tool</h1>
      </a>
    </header>
  );
}

export default Header;
