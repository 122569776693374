import { useState } from 'react';
import { useAtom } from 'jotai';
import classNames from 'classnames';
import RallyLog from './RallyLog';
import { servingTeamAtom, shotTypesAtom } from "../atoms";

const RallyAndShotLog = ({
  rallies,
  youtubePlayer,
  clicks,
  updateRally,
  updateShot,
  player1,
  player2,
  player3,
  player4,
  currentPointNumber,
}) => {

  const [showRallyAndShotLog, setShowRallyAndShotLog] = useState(true);
  const [shotTypes, setShotTypes] = useAtom(shotTypesAtom);
  const [servingTeam, setServingTeam] = useAtom(servingTeamAtom);
  let playersArr = ["N/A", player1.name, player2.name, player3.name, player4.name];

  return (
    <>
      <div className="p-2 border-b border-t-2" id="det-rally-and-shot-log">
        <h2
          className={classNames("cursor-pointer text-xl font-bold flex items-center")}
          onClick={() => setShowRallyAndShotLog(!showRallyAndShotLog)}
        >
          <svg
            className={classNames("h-4 w-4 transform mr-2 ", {
              "-rotate-90": !showRallyAndShotLog,
              "rotate-0": showRallyAndShotLog
            })}
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
          Rally Log
        </h2>
        {showRallyAndShotLog &&
          <div
            className={classNames("pt-4 2xl:flex 2xl:flex-row-reverse justify-end", {
              'hidden': !showRallyAndShotLog,
            })}
          >
            {/*<div id="current-point-shots">
              <h3 className="text-lg font-bold mb-1">Current Point Shots</h3>
              <div className="mb-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Point Number</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Shot Number</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Player</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Shot Type</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">YouTube Timestamp</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Input Timestamp</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Mouse X</th>
                      <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Mouse Y</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {clicks
                      .filter(click => click.pointNumber === currentPointNumber)
                      .map(
                        ({
                          pointNumber, shotNumber, shotPlayer, shotType, shotSec, videoTimestamp, time, x, y
                        }) =>
                          {
                            if (servingTeam === "A") {
                              if (shotNumber % 2 !== 0) {
                                playersArr = [player1.name, player2.name]
                              } else {
                                playersArr = [player3.name, player4.name]
                              }
                            } else {
                              if (shotNumber % 2 !== 0) {
                                playersArr = [player3.name, player4.name]
                              } else {
                                playersArr = [player1.name, player2.name]
                              }
                            }
                            let currentShotTypes = [];
                            if (shotNumber === 3) {
                              currentShotTypes = Object.entries(shotTypes).filter(([shotName, shotvalue], _) => shotName.includes("3rd"))
                            } else if (shotNumber > 3) {
                              currentShotTypes = Object.entries(shotTypes).filter(([shotName, shotvalue], _) => [
                                "Speed Up", "Lob", "ATP", "Ernie", "Dink", "Other", "Hand Battle", "Transition Zone - Approach", "Transition Zone - Repel", "Reset", "Unknown", "N/A"
                              ].includes(shotName)) }
                            else {
                              currentShotTypes = Object.entries(shotTypes);
                            }
                            return <tr key={`shot-log-${pointNumber}${shotNumber}`}>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{pointNumber}</td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{shotNumber}</td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500 group">
                                <select
                                  className="px-1 w-32"
                                  value={shotPlayer}
                                  onChange={updateShot(pointNumber, shotNumber, "shotPlayer")}
                                >
                                  <option value=""></option>
                                  {playersArr.map(player =>
                                    <option key={`click-shot-log-${player}-${pointNumber}-${shotNumber}`} value={player}>
                                      {player}
                                    </option>)}
                                </select>
                                <p className="break-words w-96 tooltip-text hidden text-center py-2 px-6 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">If the Player is left empty, we will use Machine Learning<br />to make an educated guess on who hit the shot.</p>
                              </td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500 group">
                                  <select
                                    className="px-1 w-16"
                                    value={shotType}
                                    onChange={updateShot(pointNumber, shotNumber, "shotType")}
                                    disabled={shotNumber === 1 || shotNumber === 2}
                                  >
                                    <option value=""></option>
                                    {currentShotTypes.map(([shotName, shotvalue], _) =>
                                      <option key={`shot-log-player-${shotvalue}-${shotNumber}-${pointNumber}`} value={shotvalue}>
                                        {shotName}
                                      </option>)}
                                  </select>
                                  <p className="break-words w-80 tooltip-text hidden text-center py-2 px-6 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">Shots listed as Other shots will be classified<br/> after submission using machine learning.</p>
                              </td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-blue-500 font-medium"><button onClick={() => youtubePlayer?.seekTo(shotSec, 'seconds')}>{videoTimestamp}</button></td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{time}</td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{x}</td>
                              <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{y}</td>
                            </tr>
                          }
                      )}
                  </tbody>
                </table>
              </div>
            </div>*/}
            <RallyLog
              rallies={rallies}
              youtubePlayer={youtubePlayer}
              clicks={clicks}
              updateRally={updateRally}
              updateShot={updateShot}
              player1={player1}
              player2={player2}
              player3={player3}
              player4={player4}
              currentPointNumber={currentPointNumber}
            />
          </div>}
      </div>
    </>
  )
}

export default RallyAndShotLog;
