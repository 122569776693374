import { Link } from "wouter";
import { useUserToken } from "../../hooks/useUserToken";
import logo from './../../assets/pklmart-logo-192.png';
import { useLocation } from "wouter";

export default function AppHeader(props) {
  const [location, setLocation] = useLocation();
  const [userToken, setUserToken] = useUserToken();

  return (
    <>
      <div className="flex flex-wrap items-center justify-between px-4 py-2  border-b border-gray-300 shadow">
        <div className="">
          <img src={logo} className="h-10" />
        </div>
        <div className="">
          <Link className="mr-3 hover:font-bold" to='/home'>Home</Link>
          <Link className="mr-3 hover:font-bold" to='/'>Data Entry</Link>
          {/* <Link className="mr-3 hover:font-bold" to='/'>Analytics</Link>
          <Link className="mr-3 hover:font-bold" to='/'>My Matches</Link> */}
          <Link className="mr-3 hover:font-bold" to='/'>Leaderboards</Link>
          <Link className="mr-3 hover:font-bold" to='/players'>Players</Link>
        </div>
        <div className="">
          {userToken ?
            <>
              <Link to='/home' className="mr-3 hover:font-bold">Home</Link>
              <a className="cursor-pointer" onClick={() => {
                setUserToken(null);
                setLocation("/home")
              }}>Logout</a>
            </> : <Link to='/home' className="mr-3 hover:font-bold">Log In</Link>}
        </div>
      </div>
    </>
  )
}
