import { atomWithStorage } from 'jotai/utils';
import { atom } from 'jotai';

// User and Global State
export const userTokenAtom = atomWithStorage("userToken");

export const storeDefault = {
  // Game Info
  playerList: [],
  player1: {id: 1, name: 'Player 1A'},
  player2: {id: 2, name: 'Player 2A'},
  player3: {id: 3, name: 'Player 3B'},
  player4: {id: 4, name: 'Player 4B'},
  player1Handedness: {value: "R", label: "Right"},
  player2Handedness: {value: "R", label: "Right"},
  player3Handedness: {value: "R", label: "Right"},
  player4Handedness: {value: "R", label: "Right"},
  player1Sex: {value: "M", label: "Male"},
  player2Sex: {value: "M", label: "Male"},
  player3Sex: {value: "M", label: "Male"},
  player4Sex: {value: "M", label: "Male"},

  topLeftPlayer: '',
  topRightPlayer: '',
  botLeftPlayer: '',
  botRightPlayer: '',
  currentServerPlayer: '',

  // Game State
  inputStyle: "standard",
  swapTeamSides: false,
  gameType: "STANDARD_DOUBLES",

  youtubeLink: "",
  youtubeId: "",
  tournamentName: "",
  tournamentYear: "",
  tournamentList: [],
  gameNumber: "1",
  skillLevel: "",
  detUserName: "",
  ballType: {label: "", value: ""},
  ballList: [],
  datePlayed: null,

  clicks: [],
  rallies: [],

  // Tracking
  pointNumber: 1,
  shotNumber: 1,
  elapsed: "0:00",
  elapsedSeconds: 0,

  servingTeam: "A",
  teamAScore: 0,
  teamBScore: 0,
  serverNumber: 2,

  thirdShotPlayerSide: "N/A",
  returnerSwitch: 0,
  serverSwitch: 0,
  teamAOriginalPos: true,
  teamBOriginalPos: true,
  shotTypes: {
    "Speed Up": "SP",
    "3rd Shot Drop": "tsDrp",
    "3rd Shot Drive": "tsDrv",
    "Return": "R",
    "Serve": "SE",
    "Lob": "L",
    "ATP": "A",
    "Ernie": "E",
    "Dink": "D",
    "Other": "O",
    "Hand Battle": "HB",
    "Transition Zone - Approach": "tzApp",
    "Transition Zone - Repel": "tzRep",
    "Reset": "Res",
    "Unknown": "U",
    "3rd Shot Lob": "tsLob",
    "N/A": "N/A",
  },
  inputModels: {"Beginner": "beginner", "Standard": "standard"},
  inputModel: "standard",
}

// ATOMS
export const player1Atom = atomWithStorage("player1", storeDefault.player1);
export const player2Atom = atomWithStorage("player2", storeDefault.player2);
export const player3Atom = atomWithStorage("player3", storeDefault.player3);
export const player4Atom = atomWithStorage("player4", storeDefault.player4);
export const playerListAtom = atomWithStorage("playerList", storeDefault.playerList);
export const player1HandednessAtom = atomWithStorage("player1Handedness", storeDefault.player1Handedness);
export const player2HandednessAtom = atomWithStorage("player2Handedness", storeDefault.player2Handedness);
export const player3HandednessAtom = atomWithStorage("player3Handedness", storeDefault.player3Handedness);
export const player4HandednessAtom = atomWithStorage("player4Handedness", storeDefault.player4Handedness);
export const player1SexAtom = atomWithStorage("player1Sex", storeDefault.player1Sex);
export const player2SexAtom = atomWithStorage("player2Sex", storeDefault.player2Sex);
export const player3SexAtom = atomWithStorage("player3Sex", storeDefault.player3Sex);
export const player4SexAtom = atomWithStorage("player4Sex", storeDefault.player4Sex);

export const topLeftPlayerAtom = atomWithStorage("topLeftPlayer", storeDefault.topLeftPlayer);
export const topRightPlayerAtom = atomWithStorage("topRightPlayer", storeDefault.topRightPlayer);
export const botLeftPlayerAtom = atomWithStorage("botLeftPlayer", storeDefault.botLeftPlayer);
export const botRightPlayerAtom = atomWithStorage("botRightPlayer", storeDefault.botRightPlayer);
export const currentServerPlayerAtom = atomWithStorage("currentServerPlayer", storeDefault.currentServerPlayer);


export const youtubeLinkAtom = atomWithStorage("youtubeLink", storeDefault.youtubeLink);
export const youtubeIdAtom = atomWithStorage("youtubeId", storeDefault.youtubeId);
export const tournamentNameAtom = atomWithStorage("tournamentName", storeDefault.tournamentName);
export const tournamentYearAtom = atomWithStorage("tournamentYear", storeDefault.tournamentYear);
export const tournamentListAtom = atomWithStorage("tournamentList", storeDefault.tournamentList);
export const gameNumberAtom = atomWithStorage("gameNumber", storeDefault.gameNumber);
export const skillLevelAtom = atomWithStorage("skillLevel", storeDefault.skillLevel);
export const detUserNameAtom = atomWithStorage("detUserName", storeDefault.detUserName);
export const ballTypeAtom = atomWithStorage("ballType", storeDefault.ballType);
export const ballListAtom = atomWithStorage("ballList", storeDefault.ballList);
export const datePlayedAtom = atomWithStorage("datePlayed", storeDefault.datePlayed);

export const clicksAtom = atomWithStorage("clicks", storeDefault.clicks);
export const ralliesAtom = atomWithStorage("rallies", storeDefault.rallies);
export const pointNumberAtom = atomWithStorage("pointNumber", storeDefault.pointNumber);
export const shotNumberAtom = atomWithStorage("shotNumber", storeDefault.shotNumber);
export const elapsedAtom = atomWithStorage("elapsed", storeDefault.elapsed);
export const elapsedSecondsAtom = atomWithStorage("elapsedSeconds", storeDefault.elapsedSeconds);
export const servingTeamAtom = atomWithStorage("servingTeam", storeDefault.servingTeam);
export const teamAScoreAtom = atomWithStorage("teamAScore", storeDefault.teamAScore);
export const teamBScoreAtom = atomWithStorage("teamBScore", storeDefault.teamBScore);
export const serverNumberAtom = atomWithStorage("serverNumber", storeDefault.serverNumber);

export const thirdShotPlayerSideAtom = atomWithStorage("thirdShotPlayerSide", storeDefault.thirdShotPlayerSide);
export const returnerSwitchAtom = atomWithStorage("returnerSwitch", storeDefault.returnerSwitch);
export const serverSwitchAtom = atomWithStorage("serverSwitch", storeDefault.serverSwitch);
export const teamAOriginalPosAtom = atomWithStorage("teamAOriginalPos", storeDefault.teamAOriginalPos);
export const teamBOriginalPosAtom = atomWithStorage("teamBOriginalPos", storeDefault.teamBOriginalPos);

export const inputStyleAtom = atomWithStorage("inputStyle", storeDefault.inputStyle);
export const swapTeamSidesAtom = atomWithStorage("swapTeamSides", storeDefault.swapTeamSides);
export const gameTypeAtom = atomWithStorage("gameType", storeDefault.gameType);

export const shotTypesAtom = atomWithStorage("shotTypes", storeDefault.shotTypes);
export const inputModelsAtom = atomWithStorage("inputModels", storeDefault.inputModels);
export const inputModelAtom = atomWithStorage("inputModel", storeDefault.inputModel);

// const displayIntroModalAtom = atomWithStorage("displayIntroModal", storeDefault.displayIntroModal);
export const displayIntroModalAtom = atom(true);

// export const topPlayerLeftSide = atomWithStorage("topPlayerLeftSide");
// export const topPlayerRightSide = atomWithStorage("topPlayerRightSide");
// export const bottomPlayerLeftSide = atomWithStorage("bottomPlayerLeftSide");
// export const bottomPlayerRightSide = atomWithStorage("bottomPlayerRightSide");
