import classNames from "classnames";
import { useState } from "react";
import {
  servingTeamAtom,
  player1Atom,
  player2Atom,
  player3Atom,
  player4Atom,
  shotTypesAtom
} from "../atoms";
import { useAtom } from 'jotai';

function RallyRow({
  youtubePlayer,
  rally: {
    thisPointNumber,
    score,
    start,
    startSec,
    end,
    endSec,
    pointOutcome,
    servingTeamId,
    rallyLen,
    serverSwitchInd,
    returnerSwitchInd,
    thirdShotPlayerSide,
    thirdShotType,
    endingPlayer,
    endingType,
  },
  clicks,
  updateRally,
  updateShot,
}) {

  const [shotTypes, setShotTypes] = useAtom(shotTypesAtom);
  const [player1, setPlayer1] = useAtom(player1Atom);
  const [player2, setPlayer2] = useAtom(player2Atom);
  const [player3, setPlayer3] = useAtom(player3Atom);
  const [player4, setPlayer4] = useAtom(player4Atom);
  const [servingTeam, setServingTeam] = useAtom(servingTeamAtom);
  const [displayClicks, setDisplayClicks] = useState(false);
  let playersArr = [];

  return(
    <>
      <tr key={`rally-row-${thisPointNumber}${pointOutcome}`}>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-900">
          <button
            onClick={() => setDisplayClicks(!displayClicks)}
          >
            {displayClicks ? "-" : "+" }
          </button>
        </td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-900">{thisPointNumber}</td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{score}</td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-blue-500 font-medium"><button onClick={() => youtubePlayer?.seekTo(startSec, 'seconds')}>{start}</button></td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-blue-500 font-medium"><button onClick={() => youtubePlayer?.seekTo(endSec, 'seconds')}>{end}</button></td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{pointOutcome}</td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{servingTeamId}</td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{rallyLen}</td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">
          <select
            className="px-1"
            value={serverSwitchInd}
            onChange={updateRally(thisPointNumber, "serverSwitchInd")}
          >
            <option value="0">N</option>
            <option value="1">Y</option>
          </select>
        </td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">
          <select
            className="px-1"
            value={returnerSwitchInd}
            onChange={updateRally(thisPointNumber, "returnerSwitchInd")}
          >
            <option value="0">N</option>
            <option value="1">Y</option>
          </select>
        </td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-700">
          <select
            className="px-1"
            value={thirdShotPlayerSide}
            onChange={updateRally(thisPointNumber, "thirdShotPlayerSide")}
          >
            <option value="N/A">N/A</option>
            <option value="L">L</option>
            <option value="R">R</option>
          </select>
        </td>
        {/*<td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-700">
        </td>*/}
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-700">
          <select
            className="px-1"
            value={thirdShotType}
            onChange={updateRally(thisPointNumber, "thirdShotType")}
          >
            <option value=""></option>
            <option value="Drop">Drop</option>
            <option value="Drive">Drive</option>
            <option value="Lob">Lob</option>
          </select>
        </td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-700">
          <select
            className="px-1"
            value={endingPlayer}
            onChange={updateRally(thisPointNumber, "endingPlayer")}
          >
            <option value=""></option>
            {["N/A", player1.name, player2.name, player3.name, player4.name].map(player => <option key={`rally-ending-${player}-${thisPointNumber}`} value={player}>{player}</option>)}
          </select>
        </td>
        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-700">
          <select
            className="px-1"
            value={endingType}
            onChange={updateRally(thisPointNumber, "endingType")}
          >
            <option value=""></option>
            <option value="Winner">Winner</option>
            <option value="Error">Error</option>
            <option value="Unforced Error">Unforced Error</option>
          </select>
        </td>
      </tr>
      <tr className={classNames({ "hidden": !displayClicks })} id={`rally_${thisPointNumber}${pointOutcome}`}>
        <td colSpan="">
        </td>
        <td colSpan="11">
          <div className="my-1 overflow-hidden shadow ring-1 ring-black ring-opacity-5">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Shot Number</th>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Player</th>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Shot Type</th>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">YouTube Timestamp</th>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Input Timestamp</th>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Mouse X</th>
                  <th className="px-1 px-1.5 text-left text-xs font-semibold text-gray-900">Mouse Y</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {clicks
                  .filter(click => click.pointNumber === thisPointNumber)
                  .map(({pointNumber, shotPlayer, shotNumber, shotType, shotSec, videoTimestamp, time, x, y}) =>
                  {
                    if (servingTeamId === "A") {
                      if (shotNumber % 2 !== 0) {
                        playersArr = [player1.name, player2.name]
                      } else {
                        playersArr = [player3.name, player4.name]
                      }
                    } else {
                      if (shotNumber % 2 !== 0) {
                        playersArr = [player3.name, player4.name]
                      } else {
                        playersArr = [player1.name, player2.name]
                      }
                    }

                    let currentShotTypes = [];
                    if (shotNumber === 3) {
                      currentShotTypes = Object.entries(shotTypes).filter(([shotName, shotvalue], _) => shotName.includes("3rd"))
                      currentShotTypes.push(["N/A", "N/A"])
                    } else if (shotNumber > 3) {
                      currentShotTypes = Object.entries(shotTypes).filter(([shotName, shotvalue], _) => [
                        "Speed Up", "Lob", "ATP", "Ernie", "Dink", "Other", "Hand Battle", "Transition Zone - Approach", "Transition Zone - Repel", "Reset", "Unknown", "N/A"
                      ].includes(shotName))
                    } else {
                      currentShotTypes = Object.entries(shotTypes);
                    }

                    if (shotNumber == rallyLen) {
                      playersArr.push("N/A");
                      currentShotTypes.push(["Ball Location", "ball"])
                    }
                    return <tr key={`rally-row-shot-log-${thisPointNumber}${shotNumber}`}>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{shotNumber}</td>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500 group">
                          <select
                            className="px-1 w-32"
                            value={shotPlayer}
                            onChange={updateShot(pointNumber, shotNumber, "shotPlayer")}
                          >
                            <option value=""></option>
                            {playersArr.map(player =>
                              <option key={`shot-log-player-${player}-${shotNumber}-${thisPointNumber}`} value={player}>
                                {player}
                              </option>)}
                          </select>
                          <p className="break-words tooltip-text hidden text-center py-2 px-2 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
                          If left empty our machine learning algorithm will infer who<br/>
                          hit the shot with a high level of accuracy based on shot<br/>
                          location, direction, player handedness, and other key factors.<br/>
                          Accuracy drops in cases of extreme poaching, or if players<br/>
                          switch sides mid-rally (for a reason other than stacking).
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500 group">
                          <select
                            className="px-1 w-32"
                            value={shotType}
                            onChange={updateShot(pointNumber, shotNumber, "shotType")}
                            disabled={shotNumber === 1 || shotNumber === 2}
                          >
                            <option value=""></option>
                            {currentShotTypes.map(([shotName, shotvalue], _) => {
                              return <option key={`shot-log-player-${shotvalue}-${shotNumber}-${pointNumber}`} value={shotvalue}>
                                {shotName}
                              </option>
                            })}
                          </select>
                          <p className="break-words w-80 tooltip-text hidden text-center py-2 px-6 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">If left empty or set to "Other", <br />our machine learning algorithm will infer the <br/> shot type based on ball location, previous shot <br/> information, and time between shots.</p>
                        </td>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-blue-500 font-medium"><button onClick={() => youtubePlayer?.seekTo(shotSec, 'seconds')}>{videoTimestamp}</button></td>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{time}</td>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{x}</td>
                        <td className="whitespace-nowrap px-1 px-1.5 text-xs text-gray-500">{y}</td>
                      </tr>
                  }
                )}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  )
}

export default RallyRow;
