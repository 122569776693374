import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({ data = [
  {
    rating: "3.5-4.0",
    returnError: 59
  },
  {
    rating: "4.0-4.5",
    returnError: 61
  },
  {
    rating: "4.5-5.0",
    returnError: 55
  },
  {
    rating: "Alex",
    returnError: 78
  },
  {
    rating: "Pros",
    returnError: 71
  },
  ]
}) => (
  <ResponsiveBar
    theme={{
      fontSize: 16,

    }}
    layout={"horizontal"}
    data={data}
    keys={["returnError"]}
    indexBy="rating"
    margin={{ top: 0, right: 0, bottom: 0, left: 150 }}
    padding={0.25}
    valueScale={{ type: "linear" }}
    colors="#18bc9c"
    animate={true}
    // enableLabel={false}
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={{
      tickSize: 0,
      tickPadding: 20,
      tickRotation: 0,
      legendPosition: "middle",
      legendOffset: -40
    }}
    labelTextColor={{
      from: 'color',
      modifiers: [
          [
              'darker',
              1.6
          ]
      ]
    }}
  />
)

export default BarChart;
