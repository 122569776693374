import logo from '../assets/pklmart-logo.png';
import classNames from 'classnames';

export default function Loading({
  isLoading,
  text
}) {
  return (
    <>
      <div
          className={classNames("relative z-50", {
            "hidden": !isLoading
          })}
        >
        <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="w-full h-screen flex">
              <div className="m-auto text-center">
                <img
                  src={logo}
                  className="h-32 mx-auto"
                />
                {text && <div className="loading-text">{text}</div>}
                <div className="pklm-loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
