
export function DataEntryToolHeader({
  currentScore,
  servingTeam,
  elapsed,
}) {

  return (
    <>
      <div className="flex justify-center text-center bg-gray-200 p-1 border-b" id="det-video-and-court-header">
        <div className="mr-6 w-32">
          <p>Serving Team</p>
          <p className="text-2xl font-bold">Team {servingTeam}</p>
        </div>
        <div className="mr-6 w-32">
          <p>Current Score</p>
          <p className="text-3xl font-bold">{currentScore}</p>
        </div>
        <div className="mr-6 w-32">
          <p>Video Time</p>
          <p className="text-2xl font-bold">{elapsed}</p>
        </div>
      </div>
    </>
  )
}
