import { useRef, useEffect, useState } from 'react';
import { useLocation } from "wouter";
import { AppHeader } from './components/Layout';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { API_HOST } from './config';

export default function Players() {

  const [players, setPlayers] = useState([]);
  const [rows, setRows] = useState(players);

  const [location, setLocation] = useLocation();
  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const filteredRows = players.filter((row) => {
      let name = `${row.first_nm} ${row.last_nm}`.toLowerCase()
      return name.includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    async function loadData() {
      let res = await fetch(`${API_HOST}/players`);
      let json = await res.json();
      setPlayers(json.players);
      setRows(json.players)
    }

    loadData();
  }, []);

  return (
    <>
      <div
        className="h-full"
      >
        <AppHeader />
        <div className="container mx-auto pt-4">
          <div className="text-center">
            <h1 className="mb-8 text-4xl font-bold">Players</h1>
          </div>
          <div className="relative flex border rounded-md pl-8 mb-4">
            <svg className="pointer-events-none absolute inset-y-0 left-4 h-full w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd"></path>
            </svg>
            <input
              className="block h-12 w-full border-0 py-0 pl-8 ml-8 pr-0 text-gray-900 placeholder:text-gray-400"
              type="text"
              value={searched}
              onChange={(e) => requestSearch(e.target.value)}
              onCancelSearch={() => cancelSearch()}
            />
          </div>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={true ? 'small' : 'medium'}
            >
              <TableBody>
                {rows.map(({
                  player_id,
                  first_nm,
                  last_nm,
                  supp_nm,
                  gender,
                  dom_hand,
                }) =>
                  <TableRow>
                    <TableCell>{first_nm} {last_nm}</TableCell>
                    <TableCell>Gender: {gender}, Dominant Hand: {dom_hand}</TableCell>
                    <TableCell align="right">
                      <button
                        onClick={() => setLocation(`/players/${player_id}`)}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline-flex items-center"
                      >
                        View Player Details
                      </button>
                    </TableCell>
                    {/* <div className="p-2 flex items-center justify-between border rounded-md mb-2 shadow" key={player_id}>
                      <div className="font-bold">
                        {first_nm} {last_nm}
                      </div>
                      <div className="flex text-sm space-x-2">
                        <div>Gender: {gender}</div>
                        <div>Dominant Hand: {dom_hand}</div>
                      </div>
                      <div>
                        <button
                          onClick={() => setLocation(`/players/${player_id}`)}
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline flex items-center"
                        >
                          View Player Details
                        </button>
                      </div>
                    </div> */}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
}
