import RightMouseIcon from './RightMouseIcon';
import LeftMouseIcon from './LeftMouseIcon';

const Guide = () => {
  return (
    <>
      <div>
        <div className="flex mr-4">
          <p className="font-bold w-48">Shot 1 (Serve):</p>
          <div className="flex items-center">
            <div className="h-32 w-48 p-4 border rounded-lg text-xs mr-2">
              <div className="flex items-start">
                <div>
                  <LeftMouseIcon className="w-16 h-16" />
                </div>
                <div className="grow">
                  <span className="font-bold">Left Click</span>
                  <p>Normal Serve</p>
                </div>
              </div>
            </div>
            <div className="h-32 w-48  p-4 border rounded-lg text-xs">
              <div className="flex items-start">
                <div>
                  <RightMouseIcon className="w-16 h-16" />
                </div>
                <div className="grow">
                  <span className="font-bold">Right Click</span>
                  <p>Serve AND serving team is stacked</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex mr-4">
          <p className="font-bold w-48">Shot 2 (Return):</p>
          <div className="flex items-center">
            <div className="h-32 w-48 p-4 border rounded-lg text-xs mr-2">
              <div className="flex">
                <div>
                  <LeftMouseIcon className="w-16 h-16" />
                </div>
                <div>
                  <span className="font-bold">Left Click</span>
                  <p>Normal Return</p>
                </div>
              </div>
            </div>
            <div className="h-32 w-48 p-4 border rounded-lg text-xs">
              <div className="flex">
                <div>
                  <RightMouseIcon className="w-16 h-16" />
                </div>
                <div>
                  <span className="font-bold">Right Click</span>
                  <p>Return AND returning team is stacked</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex mr-4">
          <p className="font-bold w-48">Shot 3 (Third Shot):</p>
          <div className="flex items-center">
            <div className="h-32 w-48  p-4 border rounded-lg text-xs mr-2 mb-auto">
              <div className="flex">
                <div>
                  <LeftMouseIcon className="w-16 h-16" />
                </div>
                <div>
                  <span className="font-bold">Left Click</span>
                  <p>Drive (and any shot that is not a drop)</p>
                </div>
              </div>
            </div>
            <div className="h-32 w-48 p-4 border rounded-lg text-xs mr-2 mb-auto">
              <div className="flex items-center">
                <div>
                  <RightMouseIcon className="w-16 h-16" />
                </div>
                <div className="mb-auto">
                  <span className="font-bold">Right Click</span>
                  <p>Drop</p>
                </div>
              </div>
            </div>
            <div className="text-xs w-80 p-2 rounded-lg border h-36">
              Before third shot is hit, indicate who is hitting it by hitting (1) or (2) key:
              <div className="flex text-center justify-center mt-4 mb-2">
                <div className="mr-4 text-center">
                  <svg fill="#000000" className="h-6 w-6 inline-block" viewBox="0 0 407.601 407.601">
                    <g>
                      <path d="M325.882,57.79H81.717c-11.748,0-21.306,9.552-21.306,21.293v249.425c0,11.738,9.552,21.291,21.306,21.291h244.165
                        c11.744,0,21.302-9.553,21.302-21.291V79.089C347.178,67.348,337.626,57.79,325.882,57.79z M151.133,273.113h-35.521V140.136
                        h-10.164v-31.765l21.923-7.335h23.761V273.113z M365.832,0H41.763C20.626,0,3.485,17.142,3.485,38.281v331.035
                        c0,21.144,17.141,38.284,38.278,38.284h324.075c21.138,0,38.278-17.141,38.278-38.284V38.275C404.116,17.142,386.976,0,365.832,0z
                        M362.274,328.509c0,20.055-16.326,36.387-36.393,36.387H81.717c-20.064,0-36.396-16.326-36.396-36.387V79.089
                        c0-20.058,16.325-36.39,36.396-36.39h244.165c20.061,0,36.393,16.326,36.393,36.39V328.509z"/>
                    </g>
                  </svg>
                  <p>Left Side Player</p>
                </div>
                <div className="text-center">
                  <svg fill="#000000" className="h-6 w-6 inline-block" viewBox="0 0 377.343 377.343">
                    <g>
                      <path d="M301.689,53.5H75.651c-10.876,0-19.725,8.842-19.725,19.713v230.909c0,10.868,8.843,19.711,19.725,19.711h226.039
                        c10.873,0,19.722-8.843,19.722-19.711V73.218C321.406,62.348,312.562,53.5,301.689,53.5z M182.551,146.928
                        c0,7.913-3.345,14.829-10.036,20.739c-6.692,5.912-14.418,8.87-23.188,8.87H115.88v35.491h66.666v29.406H83.002v-64.998
                        c0-7.839,3.304-14.704,9.922-20.581c6.611-5.885,14.341-8.826,23.18-8.826h33.566v-35.492h-33.448v11.965H82.996v-11.965
                        c0-7.838,3.305-14.703,9.924-20.585c6.611-5.885,14.339-8.821,23.18-8.821h33.452c7.79,0,14.703,2.332,20.75,6.999
                        c3.926,3.176,6.952,6.473,9.064,9.891c2.118,3.412,3.18,7.586,3.18,12.522v35.384H182.551z M338.677,0H38.662
                        C19.094,0,3.226,15.869,3.226,35.439v306.464c0,19.573,15.869,35.439,35.437,35.439h300.02c19.568,0,35.436-15.866,35.436-35.439
                        V35.434C374.117,15.869,358.25,0,338.677,0z M335.381,304.122c0,18.567-15.113,33.687-33.691,33.687H75.651
                        c-18.576,0-33.695-15.114-33.695-33.687V73.218c0-18.569,15.114-33.688,33.695-33.688h226.039
                        c18.572,0,33.691,15.114,33.691,33.688V304.122z"/>
                    </g>
                  </svg>
                  <p>Right Side Player</p>
                </div>
              </div>
              <p>“Left” and “Right are from your perspective and will be autocorrected accordingly.</p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex mr-4">
          <p className="font-bold w-48">Shot 4 (and onward):</p>
          <div className="flex items-center">
            <div className="h-32 w-48 p-4 border rounded-lg text-xs mr-2">
              <div className="flex">
                <div>
                  <LeftMouseIcon className="w-16 h-16" />
                </div>
                <div>
                  <span className="font-bold">Left Click</span>
                  <p>Any shot that is not a dink</p>
                </div>
              </div>
            </div>
            <div className="h-32 w-48  p-4 border rounded-lg text-xs mr-2">
              <div className="flex">
                <div>
                  <RightMouseIcon className="w-16 h-16" />
                </div>
                <div>
                  <span className="font-bold">Right Click</span>
                  <p>Dink</p>
                </div>
              </div>
            </div>
            <div className="h-32 w-64 p-4 border rounded-lg text-xs">
              <div className="flex">
                <div>
                  <LeftMouseIcon className="w-16 h-16" />
                </div>
                <div>
                  <span className="font-bold">Shift + Left Click</span>
                  <p>Lob</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Guide;
