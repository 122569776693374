import { useAtom } from 'jotai';

import {
  storeDefault,
  player1Atom,
  player2Atom,
  player3Atom,
  player4Atom,
} from './../atoms';

function ShotModal({
  currentShotPlayer,
  setCurrentShotPlayer,
  currentShotType,
  setCurrentShotType,
  currentShotOutcome,
  setCurrentShotOutcome,
  currentX,
  currentY,
  youtubePlayer,
  endRally,
  setDisplayShotModal,
  finishRecordClickShot,
}) {
  const [player1, setPlayer1] = useAtom(player1Atom);
  const [player2, setPlayer2] = useAtom(player2Atom);
  const [player3, setPlayer3] = useAtom(player3Atom);
  const [player4, setPlayer4] = useAtom(player4Atom);

  return (
    <div
      className="border rounded-lg p-4 shadow absolute text-sm bg-white"
      style={{
        top: `${currentY}px`,
        left: `${currentX + 60}px`
      }}
    >
      <p>{`${currentX}, ${currentY}`}</p>
      <p>Player</p>
      <select
        className="px-1"
        value={currentShotPlayer.id}
        onChange={(e) => {
          if (e.target.value == 1) {
            setCurrentShotPlayer(player1)
          } else if (e.target.value == 2) {
            setCurrentShotPlayer(player2)
          } else if (e.target.value == 3) {
            setCurrentShotPlayer(player3)
          } else if (e.target.value == 4) {
            setCurrentShotPlayer(player4)
          }
        }}
      >
        <option value=""></option>
        <option value={player1.id}>{player1.name}</option>
        <option value={player2.id}>{player2.name}</option>
        <option value={player3.id}>{player3.name}</option>
        <option value={player4.id}>{player4.name}</option>
      </select>
      <p>Shot Type</p>
      <select
        className="px-1"
        value={currentShotType}
        onChange={(e) => setCurrentShotType(e.target.value)}
      >
        <option value=""></option>
        <option value="SE">Serve</option>
        <option value="R">Return</option>
        <option value="Dink">Dink</option>
        <option value="Speed Up">Speed Up</option>
        <option value="Overhead">Overhead</option>
        <option value="ATP">ATP</option>
        <option value="Ernie"></option>
        <option value="Drop">Drop</option>
        <option value="Drive">Drive</option>
        <option value="Lob">Lob</option>
      </select>
      <p>Outcome</p>
      <select
        className="px-1"
        value={"Continue Play"}
        onChange={(e) => {
          // const outcome = e.target.value;
          // setCurrentShotOutcome(outcome)

          // logClickShot();

          // if (outcome === "Winner") {
          //   if ([1, 2].includes(currentShotPlayer.id)) {
          //     endRally("A", currentShotPlayer.name, "Winner")
          //   }
          //   else {
          //     endRally("B", currentShotPlayer.name, "Winner")
          //   }
          // }
          // else if (outcome === "Error") {
          //   if ([1, 2].includes(currentShotPlayer.id)) {
          //     endRally("B", currentShotPlayer.name, "Error")
          //   }
          //   else {
          //     endRally("A", currentShotPlayer.name, "Error")
          //   }
          // }
          // else if (outcome === "Unforced Error") {
          //   if ([1, 2].includes(currentShotPlayer.id)) { // Team A
          //     endRally("B", currentShotPlayer.name, "Unforced Error")
          //   }
          //   else {
          //     endRally("A", currentShotPlayer.name, "Unforced Error")
          //   }
          // }

          // setDisplayShotModal(false);
          // youtubePlayer.playVideo();
        }}
      >
        <option value="Continue Play">Continue Play</option>
        <option value="Winner">Winner</option>
        <option value="Error">Error</option>
        <option value="Unforced Error">Unforced Error</option>
      </select>
      <p><button
        className="bg-orange-100 hover:bg-orange-200 font-bold py-1 px-2 rounded"
        onClick={() => {
          setDisplayShotModal(false);
          finishRecordClickShot();
          youtubePlayer.playVideo();
        }}
      >Continue</button></p>
    </div>)
}

export default ShotModal;
