import { useState, useRef, useEffect } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { CheckCircle, PlayCircle, XCircle } from "../Icons";
import classNames from "classnames";
import YouTube from 'react-youtube';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'yt_shot_url',
    numeric: false,
    disablePadding: false,
    label: 'Watch',
  },
  {
    id: 'rally_len',
    numeric: false,
    disablePadding: false,
    label: 'Rally Length',
  },
  {
    id: 'atp_or_ernie_ind',
    numeric: false,
    disablePadding: false,
    label: 'Won Rally',
  },
  {
    id: 'winner_hid_int',
    numeric: false,
    disablePadding: false,
    label: 'Winner Hit',
  },
  {
    id: 'defensive_wall_ind',
    numeric: false,
    disablePadding: false,
    label: 'Long Rally',
  },
  {
    id: 'atp_ernie_ind',
    numeric: false,
    disablePadding: false,
    label: 'ATP/Ernie',
  },
  {
    id: 'hand_battle_ind',
    numeric: false,
    disablePadding: false,
    label: 'Hand Battle',
  },
  {
    id: 'lob_ind',
    numeric: false,
    disablePadding: false,
    label: 'Kitchen Lob',
  },
];

const headCellsNew = [
  {
    id: 'yt_shot_url',
    numeric: false,
    disablePadding: false,
    label: 'Watch',
  },
  {
    id: 'date_played',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'teams',
    numeric: false,
    disablePadding: false,
    label: 'Teams',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function PlayerHighlightsTable({ data = [] }) {
  const [atpFilter, setAtpFilter] = useState(false);
  const [defensiveFilter, setDefensiveFilter] = useState(false);
  const [longFilter, setLongFilter] = useState(false);
  const [handFilter, setHandFilter] = useState(false);
  const [lobFilter, setLobFilter] = useState(false);
  const [winnerFilter, setWinnerFilter] = useState(false);

  const resetFilters = () => {
    setAtpFilter(false);
    setDefensiveFilter(false);
    setLongFilter(false);
    setHandFilter(false);
    setLobFilter(false);
    setWinnerFilter(false);
  }

  const rows = data.filter((obj) => {
    if (atpFilter && obj.atp_or_ernie_ind !== 1) return false;
    if (defensiveFilter && obj.defensive_wall_ind !== 1) return false;
    if (longFilter && obj.long_rally_ind !== 1) return false;
    if (handFilter && obj.hand_battle_ind !== 1) return false;
    if (lobFilter && obj.lob_hit_ind !== 1) return false;
    if (winnerFilter && obj.winner_hit_ind !== 1) return false;

    // If the object passes all the active filters, include it in the result
    return true;
  });

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date_played');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, order, orderBy, page, rowsPerPage],
  );

  const checkOrX = (ind) => ind == 1 ? <CheckCircle /> : <XCircle />
  const [youtubeId, setYoutubeId] = useState(null);
  const [time, setTime] = useState(null);
  const [youtubePlayer, setYoutubePlayer] = useState(null);
  const videoRef = useRef(null);

  const loadVideo = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const id = urlParams.get('v');
    if (id?.length === 11) {
      setYoutubeId(id);
      setTime(urlParams.get('t').split('.')[0])
    }
  };

  const renderYoutube = () => {
    return (
      <div className="embed-container">
        <YouTube
          id="video"
          className="rounded-lg overflow-hidden"
          style={{width: "100%", height: "100%", marginTop: '32px', 'padding': '64px 24px'}}
          opts={{
            width: "100%",
            height: "100%",
            //https://developers.google.com/youtube/player_parameters
            playerVars: {
              autoplay: 1,
              start: time,
            }
          }}
          videoId={youtubeId}
          ref={videoRef}
          onReady={(event) => {
            setYoutubePlayer(event.target)
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-1/2">
          <h3 className="mb-2 mt-6 text-2xl font-semibold text-center">Highlight Reel 🎬</h3>
          <div className="text-center">
            <p className="mt-8">Select rally filters to view targeted highlights</p>
            <button
              className="mx-auto my-2 text-sm font-semibold text-white enabled:bg-red-400 enabled:hover:bg-red-600 rounded-md bg-white px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
              onClick={() => resetFilters() }
            >
              Clear All Filters
            </button>
            <p className="mt-8">View all rallies that contain (you can select multiple filters):</p>
            <div>
              <button onClick={() => setAtpFilter(!atpFilter) } className={classNames("text-sm mt-2 ml-2 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 inline-flex items-center", { "bg-pkl-500 text-white": atpFilter, "bg-white text-gray-900 hover:bg-gray-50": !atpFilter})}>ATP/Ernie</button>
              <button onClick={() => setDefensiveFilter(!defensiveFilter) } className={classNames("text-sm mt-2 ml-2 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 inline-flex items-center", { "bg-pkl-500 text-white": defensiveFilter, "bg-white text-gray-900 hover:bg-gray-50": !defensiveFilter})}>Defensive Wall</button>
              <button onClick={() => setLongFilter(!longFilter) } className={classNames("text-sm mt-2 ml-2 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 inline-flex items-center", { "bg-pkl-500 text-white": longFilter, "bg-white text-gray-900 hover:bg-gray-50": !longFilter})}>Long Rally</button>
              <button onClick={() => setHandFilter(!handFilter) } className={classNames("text-sm mt-2 ml-2 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 inline-flex items-center", { "bg-pkl-500 text-white": handFilter, "bg-white text-gray-900 hover:bg-gray-50": !handFilter})}>Hand Battle</button>
              <button onClick={() => setLobFilter(!lobFilter) } className={classNames("text-sm mt-2 ml-2 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 inline-flex items-center", { "bg-pkl-500 text-white": lobFilter, "bg-white text-gray-900 hover:bg-gray-50": !lobFilter})}>Lob</button>
              <button onClick={() => setWinnerFilter(!winnerFilter) } className={classNames("text-sm mt-2 ml-2 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 inline-flex items-center", { "bg-pkl-500 text-white": winnerFilter, "bg-white text-gray-900 hover:bg-gray-50": !winnerFilter})}>Winner Hit</button>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 p-4">
          {(youtubeId && time) ? renderYoutube() :
            <div className="h-full flex flex-col items-center justify-center border-4 rounded-lg">
              <p className="text-center text-2xl font-bold">Select a highlight from the left!</p>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 mt-2">
                  <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z" clipRule="evenodd" />
                </svg>
              </div>
            </div>}
        </div>
      </div>
      <div>
      <Box className="my-8">
          <h3 className="mb-8 text-2xl font-semibold text-center">Playlist</h3>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableHead>
                <TableRow>
                  {headCellsNew.map((headCell) => (
                    <TableCell
                      className="text-center"
                      align="center"
                      key={headCell.label}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.yt_shot_url}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <div className="text-center">
                          <button onClick={() => loadVideo(row.yt_shot_url)} target="_blank">
                            <PlayCircle />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-center">
                          {row.date_played}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-center">
                          {row.partner_and_opponents}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classNames(
                          "whitespace-nowrap px-3 py-4 text-sm text-center font-bold",
                          {
                          }
                        )}>
                          {row.winner_hit_ind == 1 ?  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-1">Winner Hit</span>: null}
                          {row.defensive_wall_ind == 1 ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-1">Defensive Wall</span> : null}
                          {row.long_rally_ind == 1 ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-1">Long Rally</span> : null}
                          {row.hand_battle_ind == 1 ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-1">Hand Battle</span> : null}
                          {row.lob_hit_ind == 1 ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-1">Lob Hit</span> : null}
                          {row.atp_or_ernie_ind == 1 ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-1">ATP/Ernie</span> : null}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </>
  );
}
