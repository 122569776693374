import { useLocation } from "wouter";

const Dashboard = ({ player_id, matches }) => {
  const [location, setLocation] = useLocation();

  return (
    <div className="grid grid-cols-4 space-x-8">
      <div>
        <button type="submit" className="mt-2 bg-pkl-500 hover:bg-pkl-400 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-pkl-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center w-full">Matches</button>
        <button type="submit" className="mt-2 hover:bg-pkl-400 rounded-md px-3 py-2 text-sm font-semibold hover:text-white shadow-sm ring-1 ring-inset ring-pkl-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center w-full" onClick={() => setLocation(`/players/${player_id}`) }>Player Profile</button>
        <button type="submit" className="mt-2 hover:bg-pkl-400 rounded-md px-3 py-2 text-sm font-semibold hover:text-white shadow-sm ring-1 ring-inset ring-pkl-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center w-full">Data Entry Tool</button>
        <button type="submit" className="mt-2 hover:bg-pkl-400 rounded-md px-3 py-2 text-sm font-semibold hover:text-white shadow-sm ring-1 ring-inset ring-pkl-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center w-full">Leaderboards</button>
        <button type="submit" className="mt-2 hover:bg-pkl-400 rounded-md px-3 py-2 text-sm font-semibold hover:text-white shadow-sm ring-1 ring-inset ring-pkl-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center w-full">Settings</button>
      </div>
      <div className="col-span-3">
        <h2 className="text-2xl font-semibold mb-4">Your Match History</h2>
        {matches.map(({
          tourn_nm,
          skill_lvl,
          w_team,
          l_team,
          score,
          url,
        }) => {
          let scores = score.split(",");
          let teamAScores = [];
          let teamBScores = [];

          scores.map(score => {
            let splitScore = score.split("-");
            teamAScores.push(splitScore[0]);
            teamBScores.push(splitScore[1]);
          });

          return (
            <div className="border-[#18BC9C] border-2 rounded-lg p-4 mb-4 relative shadow" key={url}>
              <h5 className="font-bold mb-2">
                <a href={url}>
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  {tourn_nm}
                </a>
              </h5>
              <div className="flex text-sm">
                <div className="py-1 xl:w-1/2 w-2/3">
                  Game
                </div>
                {teamBScores.map((_, idx) => <div className="p-1 w-8 text-right">{idx+1}</div>)}
              </div>
              <div className="flex bg-gray-200 px-2 rounded-lg mb-2">
                <div className="py-1 xl:w-1/2 w-2/3 flex items-center">
                  <svg fill="#18BC9C" version="1.1" className="w-4 h-4 mr-1.5" viewBox="0 0 296.193 296.193">
                    <g>
                      <path d="M213.764,95.654V10c0-5.523-4.145-10-9.667-10h-49.333v75.687C176.764,76.927,196.764,83.822,213.764,95.654z"/>
                      <path d="M139.764,0H91.097c-5.522,0-10.333,4.477-10.333,10v86.341c18-12.063,38-19.171,59-20.594V0z"/>
                      <path d="M148.096,90.475c-56.807,0-102.857,46.051-102.857,102.859s46.051,102.859,102.857,102.859
                        c56.809,0,102.859-46.051,102.859-102.859S204.904,90.475,148.096,90.475z M197.507,209.379l6.593,38.447
                        c0.965,5.628-1.349,11.314-5.968,14.671c-2.613,1.898-5.708,2.864-8.818,2.864c-2.387,0-4.784-0.569-6.979-1.724l-34.522-18.151
                        l-34.527,18.152c-2.195,1.153-4.592,1.723-6.979,1.723c-3.11,0-6.206-0.966-8.819-2.865c-4.619-3.355-6.932-9.044-5.967-14.671
                        l6.597-38.446l-27.934-27.226c-4.089-3.985-5.561-9.947-3.796-15.377c1.765-5.431,6.459-9.389,12.109-10.21l38.601-5.607
                        l17.264-34.979c2.527-5.12,7.742-8.361,13.451-8.361c5.71,0,10.924,3.241,13.451,8.361l17.263,34.98l38.599,5.607
                        c5.65,0.82,10.345,4.778,12.109,10.208c1.765,5.431,0.293,11.392-3.796,15.377L197.507,209.379z"/>
                      <polygon points="147.813,228.539 189.315,250.361 181.39,204.142 214.969,171.411 168.566,164.67 147.813,122.619 127.059,164.67
                        80.653,171.411 114.234,204.142 106.305,250.361 	"/>
                    </g>
                  </svg>
                  {w_team}
                </div>
                {teamAScores.map(teamAScore => <div className="p-1 w-8 text-right">{teamAScore}</div>)}
              </div>
              <div className="flex bg-gray-200 px-2 rounded-lg">
                <div className="py-1 xl:w-1/2 w-2/3">
                  {l_team}
                </div>
                {teamBScores.map(teamBScore => <div className="p-1 w-8 text-right">{teamBScore}</div>)}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Dashboard;
