import { useAtom } from 'jotai';
import {
  PlayerControls,
} from './../components';
import {
  swapTeamSidesAtom,
  player1Atom,
  player2Atom,
  player3Atom,
  player4Atom,
  teamAOriginalPosAtom,
  teamBOriginalPosAtom,
  servingTeamAtom,
  returnerSwitchAtom,
  serverSwitchAtom,
} from './../atoms';

export default function MlpBottom({
  endRally,
}) {
  const [player1] = useAtom(player1Atom);
  const [player2] = useAtom(player2Atom);
  const [player3] = useAtom(player3Atom);
  const [player4] = useAtom(player4Atom);
  const [swapTeamSides] = useAtom(swapTeamSidesAtom);
  const [teamAOriginalPos, setTeamAOriginalPos] = useAtom(teamAOriginalPosAtom);
  const [teamBOriginalPos, setTeamBOriginalPos] = useAtom(teamBOriginalPosAtom);
  const [servingTeam] = useAtom(servingTeamAtom);
  const [returnerSwitch] = useAtom(returnerSwitchAtom);
  const [serverSwitch] = useAtom(serverSwitchAtom);


  const swapSides = () => {
    let swap = leftPlayer;
    leftPlayer = rightPlayer;
    rightPlayer = swap;
  }

  let leftPlayer = player3;
  let rightPlayer = player4;
  let topTeam = "A";
  let bottomTeam = "B";
  let swapSidesFunction = setTeamBOriginalPos;
  let swapSidesIndicator =  teamBOriginalPos;

  if (swapTeamSides) {
    leftPlayer = player2;
    rightPlayer = player1;
    topTeam = "B";
    bottomTeam = "A";

    swapSidesFunction = setTeamAOriginalPos;
    swapSidesIndicator =  teamAOriginalPos;
  }

  if (!swapSidesIndicator) {
    swapSides();
  }

  if ((servingTeam === bottomTeam) && serverSwitch)  {
    swapSides();
  }

  if ((servingTeam !== bottomTeam) && returnerSwitch) {
    swapSides();
  }

  return (
    <>
      <div className="flex justify-center my-1">
        <PlayerControls
          playerName={leftPlayer.name}
          unforcedErrorHandler={() => endRally(topTeam, leftPlayer.name, "Unforced Error")}
          errorHandler={() => endRally(topTeam, leftPlayer.name, "Error")}
          winnerHandler={() => endRally(bottomTeam, leftPlayer.name, "Winner")}
        />
        <PlayerControls
          playerName={rightPlayer.name}
          unforcedErrorHandler={() => endRally(topTeam, rightPlayer.name, "Unforced Error")}
          errorHandler={() => endRally(topTeam, rightPlayer.name, "Error")}
          winnerHandler={() => endRally(bottomTeam, rightPlayer.name, "Winner")}
        />
      </div>
      <div className="flex justify-center">
        <div className="inline-flex rounded-md shadow-sm font-sm" role="group">
          <button
            className="bg-gray-500 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-l border border-gray-200"
            onClick={() => endRally(bottomTeam, "N/A", "Other")}
          >
            Team {bottomTeam} Rally Won
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-400 text-black font-bold py-2 px-4 border border-gray-200"
            onClick={() => endRally(`TO_${bottomTeam}`, "", "")}
          >
            Timeout
          </button>
          <button
            onClick={() => swapSidesFunction(!swapSidesIndicator)}
            className="bg-gray-500 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-r border border-gray-200"
          >
            Swap Player Sides
          </button>
        </div>
      </div>
    </>
  );
}
