import { useRef, useEffect, useState } from 'react';
import { useLocation } from "wouter";
import classNames from "classnames";
import volley from './../../assets/volley.png';
import dink from './../../assets/dink.png';
import trans from './../../assets/trans.png';
import serve from './../../assets/serve.png';
import drop from './../../assets/drop.png';
import returnImage from './../../assets/return.png';
import repel from './../../assets/repel.png';
import drive from './../../assets/drive.png';

export default function ErrorRates({
  playerId,
  overAllErrorPercent,
  serveErrorPercent,
  returnErrorPercent,
  thirdShotDriveErrorPercent,
  thirdShotDropErrorPercent,
  transitionZoneApproachErrorPercent,
  transitionRepelErrorPercent,
  dinkErrorPercent,
  spErrorPercent,
  errorRates
}) {
  const [location, setLocation] = useLocation();
  const [benchmark, setBenchmark] = useState("3.5");

  const comps = errorRates.find(rates => rates.skill_lvl == benchmark)

  const errorRateWord = (comparedTo, errorPercent) => {
    const percent = ((comparedTo - errorPercent) * 100).toFixed(2)
    return <>
      <span>
        &nbsp;<span className={classNames({"text-red-600": percent < 0, "text-[#18BC9C]": percent > 0})}>{percent < 0 ? "Worse" : "Better"}</span> than other a typical&nbsp;
        {benchmark}: <span className={classNames({"text-red-600": percent < 0, "text-[#18BC9C]": percent > 0})}>{(comparedTo * 100).toFixed(2)}%</span>
      </span>
    </>
  }

  return (
    <>
      <div>
        <div className="text-center my-4">
          Rating benchmark
          <span className="isolate inline-flex rounded-md shadow-sm ml-4">
            <button type="button" className={classNames("relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "3.0", "bg-white hover:bg-gray-50": benchmark !== "3.0" })} onClick={() => setBenchmark("3.0")}>3.0</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "3.5", "bg-white hover:bg-gray-50": benchmark !== "3.5" })} onClick={() => setBenchmark("3.5")}>3.5</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "4.0", "bg-white hover:bg-gray-50": benchmark !== "4.0" })} onClick={() => setBenchmark("4.0")}>4.0</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "4.5", "bg-white hover:bg-gray-50": benchmark !== "4.5" })} onClick={() => setBenchmark("4.5")}>4.5</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "5.0", "bg-white hover:bg-gray-50": benchmark !== "5.0" })} onClick={() => setBenchmark("5.0")}>5.0</button>
            <button type="button" className={classNames("relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10", { "bg-pkl-500 text-white": benchmark == "Pro", "bg-white hover:bg-gray-50": benchmark !== "Pro" })} onClick={() => setBenchmark("Pro")}>Pro</button>
          </span>
        </div>
        <div className="grid lg:grid-cols-3 text-center gap-16 p-4">
          <div>
            <p className="font-semibold text-lg">Overall Error Rate</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" dataslot="icon" className="h-20 mx-auto -mb-1">
              <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
            </svg>
            <p className="text-6xl font-semibold">{(overAllErrorPercent * 100).toFixed(2)}%</p>
            {errorRateWord(comps.overall_error_pct, overAllErrorPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Serve</p>
            <img className="" src={serve} />
            <p className="text-6xl font-semibold">{(serveErrorPercent * 100).toFixed(2)}%</p>
            {/* <span><span className="text-[#18BC9C]">{((comps.srv_error_pct - serveErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span>
            <p>(Compared to <span className="text-[#18BC9C]">{((comps.srv_error_pct)*100).toFixed(2)}%</span>)</p> */}
            {errorRateWord(comps.srv_error_pct, serveErrorPercent)}
          </div>
          <div>
            <p
              className="font-semibold text-lg cursor-pointer hover:text-pkl-500"
              onClick={() => setLocation(`/players/${playerId}/returns`)}
            >
              Return
            </p>
            <img className="" src={returnImage} />
            <p className="text-6xl font-semibold">{(returnErrorPercent * 100).toFixed(2)}%</p>
            {/* <span><span className="text-[#18BC9C]">{((comps.rtrn_error_pct - returnErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.rtrn_error_pct, returnErrorPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">3rd Shot Drop</p>
            <img className="" src={drop} />
            <p className="text-6xl font-semibold">{(thirdShotDropErrorPercent * 100).toFixed(2)}%</p>
            {/*<span><span className="text-[#18BC9C]">{((comps.ts_drp_error_pct - thirdShotDropErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.ts_drp_error_pct, thirdShotDropErrorPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">3rd Shot Drive</p>
            <img className="" src={drive} />
            <p className="text-6xl font-semibold">{(thirdShotDriveErrorPercent * 100).toFixed(2)}%</p>
            {/*<span><span className="text-[#18BC9C]">{((comps.ts_drv_error_pct - thirdShotDriveErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.ts_drv_error_pct, thirdShotDriveErrorPercent)}
          </div>
          <div className="">
            <p className="font-semibold text-lg">Transition Approach</p>
            <img className="" src={trans} />
            <p className="text-6xl font-semibold">{(transitionZoneApproachErrorPercent * 100).toFixed(2)}%</p>
            {/*<span><span className="text-[#18BC9C]">{((comps.tz_appr_error_pct - transitionZoneApproachErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.tz_appr_error_pct, transitionZoneApproachErrorPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Transition Repel</p>
            <img className="" src={repel} />
            <p className="text-6xl font-semibold">{(transitionRepelErrorPercent * 100).toFixed(2)}%</p>
            {/*<span><span className="text-[#18BC9C]">{((comps.tz_repel_error_pct - transitionRepelErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.tz_repel_error_pct, transitionRepelErrorPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Dink</p>
            <img className="" src={dink} />
            <p className="text-6xl font-semibold">{(dinkErrorPercent * 100).toFixed(2)}%</p>
            {/*<span><span className="text-[#18BC9C]">{((comps.dink_error_pct - dinkErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.dink_error_pct, dinkErrorPercent)}
          </div>
          <div>
            <p className="font-semibold text-lg">Speed Up</p>
            <img className="" src={volley} />
            <p className="text-6xl font-semibold">{(spErrorPercent * 100).toFixed(2)}%</p>
            {/*<span><span className="text-[#18BC9C]">{((comps.sp_error_pct - spErrorPercent)*100).toFixed(2)}%</span> better than other <span className="text-[#18BC9C] font-bold">{benchmark}</span> players</span> */}
            {errorRateWord(comps.sp_error_pct, spErrorPercent)}
          </div>
        </div>
        <div className="text-center">
          <h4 className="font-semibold text-2xl mt-8 mb-4">Road To Leveling Up!</h4>
          <p className="text-xl">Want to win more matches? Based on your error rates compared to similar players, you could improve the most by working on these shots!</p>
        </div>
      </div>
    </>
  )
}
