import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { API_HOST } from '../../config';
import { useUserToken } from '../../hooks/useUserToken';
import Alert from '@mui/material/Alert';

const LoginForm = () => {
  const [userToken, setUserToken] = useUserToken();
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const fromPasswordReset = urlParams.get('password_reset');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .required('Required'),
    }),
    onSubmit: values => {
      setError("")
      fetch(`${API_HOST}/sign_in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then(response => {
          const sessionToken = response.headers.get('X-Session-Token');
          if (sessionToken) {
            setUserToken(sessionToken);
          } else {
            return response.json()
          }
        })
        .then(data => {
          if (data?.error) {
            throw new Error(data.error);
          }
        })
        .catch(error => {
          setError(error.message);
        })
    }
  });

  return (
    <>
      {fromPasswordReset && <Alert className="mt-4" severity="success">Password reset successfully! Please login</Alert>}
      <form onSubmit={formik.handleSubmit} className="mx-auto">
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email Address</label>
          <input
            id="email"
            type="email"
            {...formik.getFieldProps('email')}
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">Password</label>
          <input
            id="password"
            type="password"
            {...formik.getFieldProps('password')}
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="text-center">
          <button type="submit" className="bg-pkl-500 hover:bg-pkl-500/90 rounded-md px-6 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300  inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-xl items-center align-center">Submit</button>
        </div>
      </form>
      {error && <Alert className="mt-4" severity="error">{error}</Alert>}
    </>
  );
};

export default LoginForm;
