import YouTube from 'react-youtube';

export default function LeftSideVideoPlayer({
  youtubeId,
  videoRef,
  setYoutubePlayer,
  youtubePlayer,
  lastRallySeconds,
  lastShotSeconds,
}) {
  return (
    <>
      <div
        id="det-video"
        className="w-1/2 h-full border-r"
      >
        {youtubeId ?
          <YouTube
            id="video"
            style={{width: "100%", height: "820px"}}
            opts={{
              width: "100%",
              height: "100%"
            }}
            videoId={youtubeId}
            ref={videoRef}
            onReady={(event) => {
              event.target.setPlaybackRate(0.5)
              setYoutubePlayer(event.target)
            }}
          /> : <div style={{width: "50%"}}></div>}
        {youtubeId && <div className="flex justify-center py-4">
          <button
            className="inline-flex items-center bg-gray-200 hover:bg-gray-300 font-bold py-2 px-4 rounded mr-3 disabled:bg-gray-100 group"
            disabled={true}
          >
            Helpful Tips
            <div className="break-words w-96 font-medium text-left tooltip-text hidden py-2 px-6 absolute z-50 bg-yellow-400 border border-yellow-600 rounded border-orange-500 text-black mt-2 group-hover:block">
              <ul className="list-disc 2xl:text-sm text-xs">
                <li className="ml-4">Click on the timestamps in the Rally or Shot log to jump back to a specific moment.</li>
                <li className="ml-4">Use the ⬅️ and ➡️ arrow keys to skip the Youtube video behind/ahead 5 seconds.</li>
                <li className="ml-4">Use the ⚙️ button to update the playback speed to your liking.</li>
              </ul>
            </div>

          </button>
          <button
            className="inline-flex items-center bg-gray-200 hover:bg-gray-300 font-bold py-2 px-4 rounded mr-3 disabled:bg-gray-100 disabled:text-gray-400"
            onClick={() => youtubePlayer.seekTo(lastRallySeconds, 'seconds')}
            disabled={!lastRallySeconds}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2">
              <path d="M7.712 4.819A1.5 1.5 0 0110 6.095v2.973c.104-.131.234-.248.389-.344l6.323-3.905A1.5 1.5 0 0119 6.095v7.81a1.5 1.5 0 01-2.288 1.277l-6.323-3.905a1.505 1.505 0 01-.389-.344v2.973a1.5 1.5 0 01-2.288 1.276l-6.323-3.905a1.5 1.5 0 010-2.553L7.712 4.82z" />
            </svg>
            Rewind to end of last rally
          </button>
          <button
            className="inline-flex items-center bg-gray-200 hover:bg-gray-300 font-bold py-2 px-4 rounded disabled:bg-gray-100 disabled:text-gray-400"
            onClick={() => youtubePlayer.seekTo(lastShotSeconds, 'seconds')}
            disabled={!lastShotSeconds}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2">
              <path d="M7.712 4.819A1.5 1.5 0 0110 6.095v2.973c.104-.131.234-.248.389-.344l6.323-3.905A1.5 1.5 0 0119 6.095v7.81a1.5 1.5 0 01-2.288 1.277l-6.323-3.905a1.505 1.505 0 01-.389-.344v2.973a1.5 1.5 0 01-2.288 1.276l-6.323-3.905a1.5 1.5 0 010-2.553L7.712 4.82z" />
            </svg>
            Rewind to last shot
          </button>
        </div>}
      </div>
    </>
  )
}
