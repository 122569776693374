import logo from './assets/pklmart-logo-200px.png';
import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { API_HOST } from './config';
import { AppHeader } from './components/Layout';
import Alert from '@mui/material/Alert';
import { Link } from 'wouter';

export default function ForgotPassword() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: values => {
      setError("")
      setSuccess("")
      fetch(`${API_HOST}/identity/password_reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data?.error) {
            throw new Error(data.error);
          } else {
            setSuccess(data.message);
          }
        })
        .catch(error => {
          setError(error.message);
        })
    }
  });

  return (
    <>
      <AppHeader />
      <div className="p-8 shadow max-w-2xl mx-auto border-2 rounded-lg mt-16">
        <img src={logo} alt="PKL Mart" className="mx-auto w-1/2" />
        <h2 className="text-xl text-gray-700 font-bold my-4 text-center">Forgot Password</h2>
        <form onSubmit={formik.handleSubmit} className="mx-auto">
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email Address</label>
            <input
              id="email"
              type="email"
              {...formik.getFieldProps('email')}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="text-center">
            <button type="submit" className="bg-pkl-500 hover:bg-pkl-500/90 rounded-md px-6 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300  inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-xl items-center align-center">Submit</button>
          </div>
        </form>
        {error && <Alert className="mt-4" severity="error">{error}</Alert>}
        {success && <Alert className="mt-4" severity="success">{success}</Alert>}

        <div className="text-center mt-6 pt-4 border-t">
          <p>
            Already have an account? <Link to='/home' className="font-bold text-pkl-500 ml-1">Login</Link>
          </p>
          <p className="mt-4"><Link className="text-pkl-500 font-bold" to='/forgot-password'>Forgot your password?</Link></p>
        </div>
      </div>
    </>
  );
}
