import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import { API_HOST } from '../../config';
import { useUserToken } from '../../hooks/useUserToken';

const SignupForm = () => {
  const [userToken, setUserToken] = useUserToken();
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      date_of_birth: '',
      location: '',
      gender: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address'),
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      date_of_birth: Yup.string().required('Date of Birth is required'),
      location: Yup.string(),
      gender: Yup.string().required('Gender is required'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
      confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      setError("")
      fetch(`${API_HOST}/sign_up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then(response => {
          const sessionToken = response.headers.get('X-Session-Token');
          if (sessionToken) {
            setUserToken(sessionToken);
          } else {
            return response.json()
          }
        })
        .then(data => {
          if (data?.error) {
            throw new Error(data.error);
          }
        })
        .catch(error => {
          setError(error.message);
        })
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="mx-auto">
        <label className="block mb-2">
          Email:
          <input
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          First Name:
          <input
            type="text"
            name="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="text-red-500">{formik.errors.first_name}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          Last Name:
          <input
            type="text"
            name="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="text-red-500">{formik.errors.last_name}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          Date of Birth:
          <input
            type="date"
            name="date_of_birth"
            value={formik.values.date_of_birth}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
            <div className="text-red-500">{formik.errors.date_of_birth}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          Location:
          <input
            type="text"
            name="location"
            value={formik.values.location}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.location && formik.errors.location ? (
            <div className="text-red-500">{formik.errors.location}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          Gender:
          <select
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {formik.touched.gender && formik.errors.gender ? (
            <div className="text-red-500">{formik.errors.gender}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          Password:
          <input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500">{formik.errors.password}</div>
          ) : null}
        </label>
        <label className="block mb-2">
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-red-500">{formik.errors.confirmPassword}</div>
          ) : null}
        </label>
        <div className="text-center">
          <button type="submit" className="bg-pkl-500 hover:bg-pkl-500/90 rounded-md px-6 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300  inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-xl items-center align-center">Submit</button>
        </div>
      </form>
      {error && <Alert className="mt-4" severity="error">{error}</Alert>}
    </>
  );
};

export default SignupForm;
