import logo from './assets/pklmart-logo-200px.png';
import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { API_HOST } from './config';
import { AppHeader } from './components/Layout';
import Alert from '@mui/material/Alert';
import { Link, useLocation } from 'wouter';

export default function ForgotPassword() {
  const [error, setError] = useState("");
  const [location, setLocation] = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const sid = urlParams.get('sid');

  if (!sid) {
    setLocation('/home');
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
      confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: values => {
      setError("")
      fetch(`${API_HOST}/identity/password_reset`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, sid }),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data?.error) {
            throw new Error(data.error);
          } else {
            setLocation('/home?password_reset=true');
          }
        })
        .catch(error => {
          setError(error.message);
        })
    }
  });

  return (
    <>
      <AppHeader />
      <div className="p-8 shadow max-w-2xl mx-auto border-2 rounded-lg mt-16">
        <img src={logo} alt="PKL Mart" className="mx-auto w-1/2" />
        <h2 className="text-xl text-gray-700 font-bold my-4 text-center">Set your new password</h2>
        <form onSubmit={formik.handleSubmit} className="mx-auto">
          <div className="mb-4">
            <label className="block mb-2">
              Password:
              <input
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="text-red-500">{formik.errors.password}</div>
              ) : null}
            </label>
            <label className="block mb-2">
              Confirm Password:
              <input
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                className="border border-gray-300 rounded-md px-3 py-2 mt-1 w-full"
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="text-red-500">{formik.errors.confirmPassword}</div>
              ) : null}
            </label>
          </div>
          <div className="text-center">
            <button type="submit" className="bg-pkl-500 hover:bg-pkl-500/90 rounded-md px-6 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300  inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-xl items-center align-center">Submit</button>
          </div>
        </form>
        {error && <Alert className="mt-4" severity="error">{error}</Alert>}
        <div className="text-center mt-6 pt-4 border-t">
          <p>
            Already have an account? <Link to='/home' className="font-bold text-pkl-500 ml-1">Login</Link>
          </p>
          <p className="mt-4"><Link className="text-pkl-500 font-bold" to='/forgot-password'>Forgot your password?</Link></p>
        </div>
      </div>
    </>
  );
}
