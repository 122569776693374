export const fetcher = (...args) => fetch(...args).then(res => res.json())
export const authFetcher = (url, token) => fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json())

export const authedGetFetcher = (url, token) =>
  fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    credentials: 'same-origin'
  }).then(res => res.json());

export const authedPostFetcher = (url, token, values) =>
  fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }),
    credentials: 'same-origin',
    body: JSON.stringify(values)
  }).then(res => res.json());

export const API_HOST = process.env.REACT_APP_API_URL;
