import { useState, useEffect } from 'react';
import { API_HOST, authedGetFetcher } from '../../config';
import { useUserToken } from '../../hooks/useUserToken';
import { AppHeader } from '../../components/Layout';
import returnImage from './../../assets/return.png';
import missedLeftCourt from './../../assets/missed-left-court.png';
import missedRightCourt from './../../assets/missed-right-court.png';
import LineChart from '../../components/Graphs/LineChart';
import { BarGraph } from '../Graphs';
import BarChart from '../Graphs/BarChart';

export default function PlayerReturns({ pid }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [data, setData] = useState([]);
  const [userToken] = useUserToken();

  /**
    {
      "error_percent_over_time": [
        {
          "as_of_dt": "2024-03-28",
          "n": 119,
          "val": "0.0084"
        },
        {
          "as_of_dt": "2024-02-28",
          "n": 134,
          "val": "0.0075"
        },
        {
          "as_of_dt": "2024-01-28",
          "n": 135,
          "val": "0.0074"
        },
        {
          "as_of_dt": "2023-12-28",
          "n": 16,
          "val": "0.0"
        },
        {
          "as_of_dt": "2023-11-28",
          "n": 1,
          "val": "0.0"
        },
        {
          "as_of_dt": "2023-10-28",
          "n": 37,
          "val": "0.027"
        }
      ]
    }
   */
  useEffect(() => {
    async function loadData() {
      let res = await authedGetFetcher(`${API_HOST}/players/${pid}/returns`, userToken)
      console.log(res)
      const returns = res.error_percent_over_time
        .sort((a, b) => new Date(a.as_of_dt) - new Date(b.as_of_dt))
        .map(({ as_of_dt, n, val }) => {
        return {
            x: new Date(as_of_dt).toLocaleString('default', { month: 'long' }),
            y: val * 100,
        }
      });
      setData([
        {
          "id": res.player,
          "data": returns,
          "color": "hsl(208, 100%, 51%);",
        }
      ]);
    }

    if (userToken) {
      loadData();
    }
  }, [userToken])

  return (
    <>
      <div className="h-full">
        <AppHeader />

        {/* Filters */}
        <div className="grid grid-cols-3 border-b-4 shadow divide-x-4">
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-2 text-center">Pick Your Dataset</h2>
            <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
              1 Day
            </div>
            <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
              7 Days
            </div>
            <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
              14 Days
            </div>
            <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
              30 Days
            </div>
            <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
              Custom
            </div>
            <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
              Select Matches
            </div>
          </div>
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-2 text-center">Pick a Category</h2>
            <div className="grid grid-cols-2 space-x-4">
              <div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  Serve
                </div>
                <div className="text-center mb-1 border border-pkl-500 bg-pkl-500 rounded text-xl w-64 mx-auto font-semibold text-white">
                  Return
                </div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  3rd Shop Drop
                </div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  3rd Shot Drive
                </div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  Transition
                </div>
              </div>
              <div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  Transition Repel
                </div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  Dink
                </div>
                <div className="text-center mb-1 border border-pkl-500 rounded text-xl w-64 mx-auto font-semibold">
                  Volley
                </div>
              </div>
            </div>
          </div>
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-2 text-center">Quick View</h2>
            <div>
              <p className="text-lg font-semibold mb-2">Summary</p>
              <p className="text-lg font-semibold mb-2">Errors</p>
              <p className="text-lg font-semibold mb-2">Trends</p>
              <p className="text-lg font-semibold mb-2">Missed Returns Visualized</p>
              <p className="text-lg font-semibold mb-2">Return Depth Visualized</p>

            </div>
          </div>
        </div>

        {/* Return Summary Section */}
        <div className="">
          <div className="text-center pt-12">
            <h1 className="text-4xl font-semibold text-gray-700 mb-16">Return Summary</h1>
            <img className="w-96 inline-block" src={returnImage} />
            <div className="flex justify-center items-center py-16">
              <div className="flex">
                <div className="text-center">

                <div className="text-center border-pkl-500 border-r-4 pr-24 w-128">
                  <p className="text-9xl text-gray-700">35</p>
                  <p className="text-gray-600">Returns in the last 7 days</p>
                </div>
                </div>

                <div className="text-center pl-24 w-128">
                  <p className="text-9xl text-gray-700">15%</p>
                  <p className="text-gray-600">Returns led to opponent<br/>3rd shot error</p>
                </div>
              </div>
            </div>
          </div>

          {/* Errors Section */}
          <div className="p-8 bg-gray-200">
            <h2 className="text-3xl font-semibold text-gray-700 mb-8 text-center">Errors</h2>
            <div className="container mx-auto">
              <div className="flex justify-between items-start">
                <div className="text-center flex-1">
                  <h2 className="text-xl font-semibold mb-2">Percent of missed returns</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" dataslot="icon" className="h-64 mx-auto -mb-5">
                    <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                  </svg>
                  <p className="text-4xl text-gray-800 mb-2">7.3% Error Rate</p>
                  <p className="text-sm text-gray-700">+1.4% better than other players</p>
                  <p className="text-sm text-gray-700">-0.7% worse than players at your goal rating</p>
                </div>
                <div className="text-center flex-1">
                  <h2 className="text-xl font-semibold mb-2">Benchmark</h2>
                  <p>Your return error rate compared to other player ratings</p>
                  {/* Insert benchmark bar chart here */}
                  {/* <BarGraph/> */}
                  <div style={{height: '300px', width: '650px'}}>
                    <BarChart />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Trends Section */}
          <div className="p-8 mb-16">
            <h2 className="text-3xl font-semibold text-gray-700 mb-8 text-center">Trends</h2>
            <div className="container mx-auto flex justify-between">
              <div>
                <h3 className="text-2xl font-semibold text-gray-700 mb-8 text-center">Error Rate</h3>
                <div style={{height: '300px', width: '550px'}}>
                  <LineChart data={data} />
                </div>
              </div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-700 mb-8 text-center">% of returns led to opponent 3rd shot error</h3>
                <div style={{height: '300px', width: '550px'}}>
                  <LineChart data={data} />
                </div>
              </div>
            </div>
          </div>

          {/* Visualized Section */}
          <div className="p-8">
            <div className="container mx-auto">
              <h2 className="text-3xl font-semibold text-gray-700 mb-8 text-center">Missed Returns Visualized</h2>
              <p className="mb-12">View a visualization of your missed returns below. Look for patterns like serve depth and placement that may help you to reduce return errors. notice if you are missing proportionally more returns on your forehand or backhand.</p>
              <h3 className="text-2xl font-semibold text-gray-700 mb-8 text-center">Select from below metrics to change the visualizations</h3>
              <div className="grid grid-cols-3 space-x-8 mb-8">
                <div className="rounded-3xl bg-pkl-500 text-white text-center p-8">
                  <p className="text-9xl">15%</p>
                  <p className="font-bold">of your missed returns were long</p>
                </div>
                <div className="rounded-3xl bg-pkl-500 text-white text-center p-8">
                  <p className="text-9xl">26%</p>
                  <p className="font-bold">of your missed returns hit the net</p>
                </div>
                <div className="rounded-3xl bg-pkl-500 text-white text-center p-8">
                  <p className="text-9xl">11%</p>
                  <p className="font-bold">of your missed returns were wide</p>
                </div>
              </div>
              <div className="rounded-3xl bg-pkl-300 text-white text-center px-8 py-6 mb-16">
                <p className="text-2xl font-bold mb-4">Legend</p>
                <div className="flex justify-between">
                  <p className="font-bold flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-red-500 w-8 rotate-[32deg]">
                      <path d="M6.3 2.84A1.5 1.5 0 0 0 4 4.11v11.78a1.5 1.5 0 0 0 2.3 1.27l9.344-5.891a1.5 1.5 0 0 0 0-2.538L6.3 2.841Z" />
                    </svg>
                    Location of ball contact with your paddle
                  </p>
                  <p className="font-bold flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-red-500 w-8">
                      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                    </svg>
                    Location of bounce
                  </p>
                  <p className="font-bold flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-red-500 w-8">
                      <path fillRule="evenodd" d="M4 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 10Z" clipRule="evenodd" />
                    </svg>
                    Estimated ball trajectory
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="text-center">
                  <h2 className="text-xl font-semibold mb-2">Missed Returns From Left</h2>
                  <img className="inline-block w-96" src={missedLeftCourt} />
                </div>
                <div className="text-center">
                  <h2 className="text-xl font-semibold mb-2">Missed Returns From Right</h2>
                  <img className="inline-block w-96" src={missedRightCourt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
