import {
  clicksAtom,
  shotNumberAtom,
  returnerSwitchAtom,
  serverSwitchAtom,
  ralliesAtom,
  pointNumberAtom,
  gameTypeAtom,
  teamAScoreAtom,
  teamBScoreAtom,
  servingTeamAtom,
  serverNumberAtom,
  swapTeamSidesAtom
} from './../atoms';
import { useAtom} from 'jotai';
import CurrentShotLog from './CurrentShotLog';

export default function RightSideTools({
  downloadCsvs,
  clearGameData,
  insertData,
  clearAllRallies,
  clearCanvas,
  youtubePlayer,
  drawCircle,
  updateShot
}) {

  const [clicks, setClicks] = useAtom(clicksAtom);
  const [returnerSwitch, setReturnerSwitch] = useAtom(returnerSwitchAtom);
  const [serverSwitch, setServerSwitch] = useAtom(serverSwitchAtom);
  const [shotNumber, setShotNumber] = useAtom(shotNumberAtom);
  const [rallies, setRallies] = useAtom(ralliesAtom);

  const [servingTeam, setServingTeam] = useAtom(servingTeamAtom);
  const [teamAScore, setTeamAScore] = useAtom(teamAScoreAtom);
  const [teamBScore, setTeamBScore] = useAtom(teamBScoreAtom);
  const [serverNumber, setServerNumber] = useAtom(serverNumberAtom);
  const [pointNumber, setPointNumber] = useAtom(pointNumberAtom);
  const [gameType, setGameType] = useAtom(gameTypeAtom);
  const [swapTeamSides, setSwapTeamSides] = useAtom(swapTeamSidesAtom);

  const removeLastShot = () => {
    // Get shots for the current point
    const currentPointShots = clicks.filter(click => click.pointNumber === pointNumber)

    if (currentPointShots.length > 0) {
      const lastShot = currentPointShots[0];

      if ((lastShot.shotNumber == 2) && (returnerSwitch === 1)) {
        setReturnerSwitch(0);
      }

      if ((lastShot.shotNumber == 1) && (serverSwitch === 1)) {
        setServerSwitch(0);
      }

      const currentPointShotsSliced = currentPointShots.slice(1);
      // Remove last shot and set `clicks`
      setClicks(clicks.slice(1))
      // Decrement the shot counter because we removed the last click
      setShotNumber(shotNumber-1)

      // Redraw all shots
      clearCanvas();
      currentPointShotsSliced.forEach(shot => {
        drawCircle(shot.x, shot.y, shot.shotType);
      });
    }
  }

  const resetRally = () => {
    const newClicks = clicks.filter(click => click.pointNumber !== pointNumber)
    setClicks(newClicks);
    setShotNumber(1);
    clearCanvas();
    setReturnerSwitch(0);
    setServerSwitch(0);
    youtubePlayer.seekTo(rallies[0]?.endSec, 'seconds');
  }

  const clearLastRally = async () => {
    if (rallies.length > 0) {
      const lastRally = rallies[0];
      await setRallies(rallies.slice(1));
      const ptNbr = pointNumber === 1 ? 1 : pointNumber - 1;
      setClicks(clicks.filter(click => click.pointNumber !== ptNbr));
      setPointNumber(pointNumber-1);
      setReturnerSwitch(0);
      setServerSwitch(0);
      youtubePlayer?.seekTo(lastRally?.startSec, 'seconds')

      switch(gameType) {
        case "STANDARD_DOUBLES": {
          clearLastRallyStandardDoubles(lastRally);
          break;
        }
        case "MLP_DOUBLES_2023": {
          clearLastRallyMlpDoubles(lastRally);
          break;
        }
        case "MLP_DOUBLES_2023_NO_FREEZE": {
          clearLastRallyMlpDoublesNoFreeze(lastRally);
          break;
        }
        case "MLP_DOUBLES_2024": {
          clearLastRallyMlpDoubles2024(lastRally);
          break;
        }
        case "SINGLES": {
          clearLastRallySingles(lastRally);
          break;
        }
      }
    }
  }

  const clearLastRallyMlpDoubles = (lastRally) => {
    if (lastRally.pointOutcome === "A") {
      if (lastRally.servingTeamId === "A") {
        setTeamAScore(teamAScore-1);
      } else if (lastRally.servingTeamId === "B") {
        if ((teamAScore < 20) && (teamBScore < 20)) {
          setTeamAScore(teamAScore-1)
        }
      }

      setServingTeam(lastRally.servingTeamId)
    } else if (lastRally.pointOutcome === "B") {
      if (lastRally.servingTeamId === "B") {
        setTeamBScore(teamBScore-1);
      } else if (lastRally.servingTeamId === "A") {
        if ((teamBScore < 20) && (teamAScore < 20)) {
          setTeamBScore(teamBScore-1)
        }
      }

      setServingTeam(lastRally.servingTeamId)
    }
  }

  const clearLastRallyMlpDoubles2024 = lastRally => {
    if (lastRally.pointOutcome === "A") {
      setTeamAScore(teamAScore-1);
      setServingTeam(lastRally.servingTeamId)
    } else if (lastRally.pointOutcome === "B") {
      setTeamBScore(teamBScore-1);
      setServingTeam(lastRally.servingTeamId)
    }
  }

  const clearLastRallyMlpDoublesNoFreeze = (lastRally) => {
    if (lastRally.pointOutcome === "A") {
      if (lastRally.servingTeamId === "A") {
        setTeamAScore(teamAScore-1);
      } else if (lastRally.servingTeamId === "B") {
        if (teamAScore < 20) {
          setTeamAScore(teamAScore-1)
        }
      }

      setServingTeam(lastRally.servingTeamId)
    } else if (lastRally.pointOutcome === "B") {
      if (lastRally.servingTeamId === "B") {
        setTeamBScore(teamBScore-1);
      } else if (lastRally.servingTeamId === "A") {
        if (teamBScore < 20) {
          setTeamBScore(teamBScore-1)
        }
      }

      setServingTeam(lastRally.servingTeamId)
    }
  }

  const clearLastRallyStandardDoubles = (lastRally) => {
    if (lastRally.pointOutcome === "A") {
      if (lastRally.servingTeamId === "A") {
        setTeamAScore(teamAScore-1);
      } // Team A won point but Team B Served
      else if (serverNumber === 2) {
        setServerNumber(serverNumber-1)
      } else { // TEAM A Scored AND team B Served AND Server Number 1
        setServerNumber(2)
        setServingTeam("B")
      }
    }
    else if (lastRally.pointOutcome === "B"){
      if (lastRally.servingTeamId === "B") {
        setTeamBScore(teamBScore-1);
      }
      else if (serverNumber === 2) {
        setServerNumber(serverNumber-1)
      } else {
        setServerNumber(2)
        setServingTeam("A")
      }
    }
  }

  const clearLastRallySingles = () => {
  }

  return (
    <>
      <div
        className="flex flex-col border-l grow"
        id="det-right-side-tools"
      >
        <div className="">
          <div className="bg-gray-100 font-bold p-1 pl-2 mb-2">
            Utilities
          </div>
          <div className="pl-2">
            <button
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline-flex items-center mr-2"
              onClick={() => setSwapTeamSides(!swapTeamSides)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              Swap team sides
            </button>
          </div>
        </div>
        <div className="bg-gray-100 font-bold p-1 mt-6 mb-2 pl-2">
          Reset
        </div>
        <div className="pl-2">
          <div className="mb-2">
            <button
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline flex items-center"
              onClick={removeLastShot}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
              </svg>
              Remove Last Shot
            </button>
          </div>
          <div className="mb-2">
            <button
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
              onClick={() => {
                if (window.confirm("Are you sure you want to clear ALL shots for the current rally?"))
                  resetRally()
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
              </svg>

              Reset Current Rally
            </button>
          </div>
          <div className="mb-2">
            <button
              className="disabled:bg-gray-100 disabled:text-gray-400 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center align-center"
              onClick={() => {
                if (window.confirm("Are you sure you want to clear the last rally?"))
                  clearLastRally()
              }}
              disabled={(shotNumber !== 1) || (pointNumber === 1)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Clear Last Rally
            </button>
          </div>
          <div className="">
            <button
              className="text-white enabled:bg-red-400 enabled:hover:bg-red-600 rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
              onClick={() => {
                if (window.confirm("Are you sure you want to clear all rallies? This action is irreversible."))
                  clearAllRallies();
              }}
            >
              💣 Clear All Rallies
            </button>
          </div>
        </div>
        <div className="bg-gray-100 font-bold p-1 mt-6 mb-2 pl-2">
          Submit game
        </div>
        <div className="pl-2">
          <button
            className="bg-green-700 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-500 flex items-center mb-2"
            onClick={async () => {
              if (window.confirm("Are sure the game is complete?\n\nPressing OK will download data, and send data to the pklmart servers.")) {
                await insertData()
                downloadCsvs()
              }
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
            Complete Game
          </button>
          <button
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
            onClick={() => {
              if (window.confirm("Are sure you want to clear the current game's data? This includes all rally/shot info. This action is irreversible!"))
                clearGameData()
            }}
          >
            🧨 Clear All Game Data
          </button>
        </div>
        <div className="bg-gray-100 font-bold p-1 mt-6 mb-2 pl-2">
          Shot Log
        </div>
        <div className="max-h-96 overflow-y-auto">
          <CurrentShotLog clicks={clicks} updateShot={updateShot} youtubePlayer={youtubePlayer} />
        </div>
      </div>
    </>
  )
}
