import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { API_HOST, authedGetFetcher } from '../../config';
import { useUserToken } from '../../hooks/useUserToken';
import React from 'react';
import ClaimPlayer from './ClaimPlayer';
import MailVerification from './MailVerification';
import LoadingBackdrop from '../shared/LoadingBackdrop';
import { useLocation } from "wouter";
import Dashboard from './Dashboard';

const UserHome = () => {
  const [location, setLocation] = useLocation();
  const [userToken] = useUserToken();
  const [user, setUser] = useState(null);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    async function loadData() {
      let res = await authedGetFetcher(`${API_HOST}/user`, userToken)
      setUser(res);
    }

    if (userToken) {
      loadData();
    }
  }, [userToken]);

  useEffect(() => {
    async function loadData() {
      if (user?.player_link_verified) {
        let res = await authedGetFetcher(`${API_HOST}/user/matches`, userToken);
        setMatches(res);
      }
    }
    loadData();
  }, [user]);

  if (!user) {
    return <LoadingBackdrop />;
  }

  const {
    first_name,
    verified,
    player_id,
    player_link_verified,
    player_name,
  } = user; // user

  return (
    <>
      <div className="p-8 shadow w-2/3 mx-auto border-2 rounded-lg mt-16">
        <MailVerification verified={verified} userToken={userToken} />

        <h2 className="text-4xl font-bold mb-8">Welcome to pklsmart {first_name ? ` ${first_name}` : ""}!</h2>

        {!player_link_verified ?
          <ClaimPlayer
            userToken={userToken}
            player_id={player_id}
            player_link_verified={player_link_verified}
            player_name={player_name}
          /> :
          <Dashboard
            player_id={player_id}
            matches={matches}
          />}
      </div>
    </>
  );
};

export default UserHome;
