import { useLocation } from "wouter";

export default function PlayerPeers({
  peers = []
}) {
  const [location, setLocation] = useLocation();

  return (
    <>
      <div className="xl:w-1/2 p-4">
        <h3 className="text-center font-semibold text-2xl mb-4">Recent Teammates</h3>
        {peers.map(({
          player_id,
          first_nm,
          last_nm,
          gender,
          dom_hand,
        }) =>
            <div className="p-2 flex items-center mb-4" key={player_id}>
              <div className="flex items-center">
                <div className="mx-auto relative inline-flex text-4xl h-16 w-16 rounded-full align-center items-center justify-center bg-[#18BC9C] text-white font-semibold mr-4">
                  {first_nm[0]}
                </div>
                <div>
                  <div>
                    <strong className='text-xl'>{first_nm} {last_nm}</strong>
                  </div>
                  <p>{gender === "F" ? "Female" : "Male"}</p>
                </div>
              </div>
              <div className="ml-auto">
                <button
                  className="text-sm ml-2 rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline flex items-center"
                  onClick={() => setLocation(`/players/${player_id}`)}
                >
                  View Player
                </button>
              </div>
            </div>
        )}
      </div>
    </>
  )
}
