import { Link } from 'wouter';
import React, { useState } from 'react';
import logo from './../../assets/pklmart-logo-200px.png';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

 return (
    <div className="p-8 shadow max-w-2xl mx-auto border-2 rounded-lg mt-16">
      <img src={logo} alt="PKL Mart" className="mx-auto w-1/2" />
      <h2 className="text-xl text-gray-700 font-bold my-4 text-center">{isLogin ? 'Welcome Back!' : 'Sign Up'}</h2>
      {isLogin ? <LoginForm /> : <SignupForm />}
      <div className="text-center mt-6 pt-4 border-t">
        <p>
            {isLogin ?
              <>Don't have an account?</>:
              <>Already have an account?</>}
          <button
            onClick={toggleForm}
            className="font-bold text-pkl-500 ml-1"
          >
            {isLogin ? 'Sign Up' : 'Login'}
          </button>
        </p>
        <p className="mt-4"><Link className="text-pkl-500 font-bold" to='/forgot-password'>Forgot your password?</Link></p>
      </div>
    </div>
   )
}
export default LoginPage;
