import { useRef, useEffect, useState } from 'react';
import { useLocation } from "wouter";

function ProcessedGames() {
  const API_HOST = process.env.REACT_APP_API_URL;

  const [games, setGames] = useState([]);

  const [location, setLocation] = useLocation();

  useEffect(() => {
    async function loadData() {
      let res = await fetch(`${API_HOST}/processed_games`);
      let json = await res.json();
      setGames(json.processed_games);
    }

    loadData();
  }, []);

  return (
    <>
      <div
        className="h-full p-8"
      >
        <h1 className="mb-8 text-4xl font-bold">Processed Games</h1>
        {games.map(({
          raw_game_id,
          tourn_name,
          tourn_yr,
          game_nbr,
          player_a1,
          player_a2,
          player_a3,
          player_a4
        }) =>
          <>
            <div className="p-6 border rounded-2xl mb-8 shadow" key={raw_game_id}>
              <div className="font-bold mb-2">
                {tourn_name} {tourn_yr} - Game {game_nbr}
              </div>
              <div className="flex items-center mb-4">
                <div>
                  {player_a1}<br/>
                  {player_a2}
                </div>
                <div className="mx-8">
                  vs
                </div>
                <div>
                <div>
                  {player_a3}<br/>
                  {player_a4}
                </div>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setLocation(`/load-game-data/${raw_game_id}`)}
                  className="bg-green-300 hover:bg-green-200 font-bold py-2 px-4 rounded border disabled:bg-gray-50 disabled:text-gray-500">Load Game
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ProcessedGames;
