import { Link, Route, Switch} from "wouter";
import Det from "./Det";
import ProcessedGames from "./ProcessedGames";
import LoadGameData from "./LoadGameData";
import RawGames from "./RawGames";
import LoadRawGameData from "./LoadRawGameData";
import Players from "./Players";
import Player from "./Player";
import { PlayerReturns } from "./components/PlayerReturns";
import Home from "./Home";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";

const App = () => (
  <>
    {/* <Link href="/users/1">
      <a className="link">Profile</a>
    </Link>

    <Route path="/about">About Us</Route>
    <Route path="/users/:name">{(params) => <div>Hello, {params.name}!</div>}</Route> */}
    <Switch>
      <Route path="/home"><Home /></Route>
      <Route path="/players/:id/returns">{(params) => <PlayerReturns pid={params.id} />}</Route>
      <Route path="/players/:id">{(params) => <Player pid={params.id} />}</Route>
      <Route path="/players"><Players /></Route>
      <Route path="/raw-games"><RawGames /></Route>
      <Route path="/processed-games"><ProcessedGames /></Route>
      <Route path="/load-game-data/:id"><LoadGameData /></Route>
      <Route path="/load-raw-game-data/:id"><LoadRawGameData /></Route>
      <Route path="/forgot-password"><ForgotPassword /></Route>
      <Route path="/set-password"><SetPassword /></Route>
      <Route><Det /></Route>
    </Switch>
  </>
);

export default App;
