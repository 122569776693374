import { useAtom } from 'jotai';
import StandardTop from './StandardTop';
import MlpTop from './MlpTop';
import { gameTypeAtom } from './../atoms';

export default function TopTeam({
  endRally,
}) {
  const [gameType] = useAtom(gameTypeAtom);

  let component;

  switch(gameType) {
    case "STANDARD_DOUBLES": {
      component = <StandardTop endRally={endRally} />
      break;
    }
    case "MLP_DOUBLES_2023": {
      component = <MlpTop endRally={endRally} />;
      break;
    }
    case "MLP_DOUBLES_2023_NO_FREEZE": {
      component = <MlpTop endRally={endRally} />;
      break;
    }
    case "MLP_DOUBLES_2024": {
      component = <MlpTop endRally={endRally} />;
      break;
    }
    case "SINGLES": {
      break;
    }
  }

  return (
    <>
      {component}
    </>
  )
}
