import Alert from '@mui/material/Alert';
import { API_HOST } from '../../config';
import { useState } from 'react';


const MailVerification = ({ verified, userToken }) => {
  const [emailSent, setEmailSent] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const sid = urlParams.get('sid');

  if (sid) {
    verifyEmail();
  }

  const verifyEmail = () => {
    fetch(`${API_HOST}/identity/email_verification?`+ new URLSearchParams({sid}), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
    }).then(res =>
      res.json()
    ).then(data => {
    });
  }

  const sendEmailVerification = () => {
    fetch(`${API_HOST}/identity/email_verification`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin'
    }).then(res => res.json());
    setEmailSent(true);
  }

  return (
    <>
      {(!verified && !emailSent) && <Alert className="mt-4" severity="error">Your email is not verified. Please verify your email or
        <button
          className="ml-1 underline font-bold" onClick={sendEmailVerification}
        >click here</button> to resend the email.</Alert>}
      {emailSent && <Alert className="mt-4" severity="success">Verify email sent successfully! Please check your email to verify your account.</Alert>}
    </>
  );
}

export default MailVerification;
