import { useAtom } from 'jotai';
import { useEffect } from 'react';

import {
  PlayerControls,
} from './../components';
import {
  swapTeamSidesAtom,
  player1Atom,
  player2Atom,
  player3Atom,
  player4Atom,
  gameTypeAtom,
  teamAOriginalPosAtom,
  teamBOriginalPosAtom,
  servingTeamAtom,
  returnerSwitchAtom,
  serverSwitchAtom,
  teamAScoreAtom,
  teamBScoreAtom,
  serverNumberAtom,
  pointNumberAtom,
  ralliesAtom,
  topRightPlayerAtom,
  topLeftPlayerAtom,
  botLeftPlayerAtom,
  botRightPlayerAtom,
  currentServerPlayerAtom,
} from './../atoms';

export default function StandardTop({
  endRally,
}) {
  const [gameType] = useAtom(gameTypeAtom);
  const [player1] = useAtom(player1Atom);
  const [player2] = useAtom(player2Atom);
  const [player3] = useAtom(player3Atom);
  const [player4] = useAtom(player4Atom);
  const [swapTeamSides] = useAtom(swapTeamSidesAtom);
  const [teamAScore] = useAtom(teamAScoreAtom);
  const [teamBScore] = useAtom(teamBScoreAtom);

  const [servingTeam] = useAtom(servingTeamAtom);
  const [returnerSwitch] = useAtom(returnerSwitchAtom);
  const [serverSwitch] = useAtom(serverSwitchAtom);

  const [teamAOriginalPos, setTeamAOriginalPos] = useAtom(teamAOriginalPosAtom);
  const [teamBOriginalPos, setTeamBOriginalPos] = useAtom(teamBOriginalPosAtom);

  const [serverNumber, setServerNumber] = useAtom(serverNumberAtom);
  const [pointNumber, setPointNumber] = useAtom(pointNumberAtom);
  const [rallies, setRallies] = useAtom(ralliesAtom);

  const [topLeftPlayer, setTopLeftPlayer] = useAtom(topLeftPlayerAtom);
  const [topRightPlayer, setTopRightPlayer] = useAtom(topRightPlayerAtom);
  const [currentServerPlayer, setCurrentServerPlayer] = useAtom(currentServerPlayerAtom);

  let leftPlayer = player1;
  let rightPlayer = player2;
  let topTeam = "A";
  let bottomTeam = "B";

  const swapSides = () => {
    let swap = leftPlayer;
    leftPlayer = rightPlayer;
    rightPlayer = swap;
  }

  if (swapTeamSides) {
    leftPlayer = player4;
    rightPlayer = player3;
    topTeam = "B";
    bottomTeam = "A";

    if ((teamBScore % 2) !== 0) {
      swapSides();
    }
  } else {
    if ((teamAScore % 2) !== 0) {
      swapSides();
    }
  }

  if ((servingTeam === topTeam) && serverSwitch)  {
    swapSides();
  }

  if ((servingTeam !== topTeam) && returnerSwitch) {
    swapSides();
  }


  useEffect(() => {
    setTopLeftPlayer(leftPlayer);
    setTopRightPlayer(rightPlayer);
  }, [leftPlayer, rightPlayer])

  return (
    <>
      <div className="flex justify-center">
        <div className="inline-flex rounded-md shadow-sm font-sm" role="group">
          <button
            className="bg-gray-300 hover:bg-gray-200 text-black font-bold py-2 px-4 rounded-l border border-gray-200"
            onClick={() => endRally(topTeam, "N/A", "Other")}
          >
            Team {topTeam} Rally Won
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-200 text-black font-bold py-2 px-4 border rounded-r border-gray-200"
            onClick={() => endRally(`TO_${topTeam}`, "", "")}
          >
            Timeout
          </button>
        </div>
      </div>
      <div className="flex justify-center my-1">
        <PlayerControls
          server={currentServerPlayer.name}
          playerName={leftPlayer.name}
          unforcedErrorHandler={() => endRally(bottomTeam, leftPlayer.name, "Unforced Error")}
          errorHandler={() => endRally(bottomTeam, leftPlayer.name, "Error")}
          winnerHandler={() => endRally(topTeam, leftPlayer.name, "Winner")}
        />
        <PlayerControls
          server={currentServerPlayer.name}
          playerName={rightPlayer.name}
          unforcedErrorHandler={() => endRally(bottomTeam, rightPlayer.name, "Unforced Error")}
          errorHandler={() => endRally(bottomTeam, rightPlayer.name, "Error")}
          winnerHandler={() => endRally(topTeam, rightPlayer.name, "Winner")}
        />
      </div>
    </>
  )
}
