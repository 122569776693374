import { useRoute, useLocation } from "wouter";
import Loading from "./components/Loading";
import { useRef, useEffect, useState } from 'react';

function LoadGameData() {
  const [match, params] = useRoute("/load-game-data/:id");
  const [location, setLocation] = useLocation();
  const API_HOST = process.env.REACT_APP_API_URL;

  useEffect(() => {

    async function loadData() {
      let res = await fetch(`${API_HOST}/players/select_all`);
      let json = await res.json();

      setLocation("/");
    }

    loadData();
  }, [])

  return (
    <>
      <Loading isLoading={true} text="Loading game data..." />
    </>
  )
}

export default LoadGameData;
