import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, Autocomplete } from '@mui/material';
import { API_HOST, authedGetFetcher } from '../../config';

const ClaimPlayer = ({ userToken, player_id, player_link_verified, player_name, player_link_verified_id }) => {
  const [players,setPlayers] = useState([]);
  const [playerId, setPlayerId] = useState(player_id);
  const [playerName, setPlayerName] = useState(player_name);
  const [playerLinkVerified, setPlayerLinkVerified] = useState(player_link_verified);
  const [playerLinkVerifiedId, setPlayerLinkVerifiedId] = useState(player_link_verified_id);
  const [removeButtonText, setRemoveButtonText] = useState('Click here to remove player claim');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    async function loadData() {
      let res = await fetch(`${API_HOST}/players`);
      let json = await res.json();
      setPlayers(json.players);
    }

    if (userToken && !player_link_verified) {
      loadData();
    }
  }, [userToken]);

  const handleClaim = (values) => {
    setSubmitDisabled(true);
    fetch(`${API_HOST}/user_player_links`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
      body: JSON.stringify({
        player_id: values.playerId
      })
    })
    .then(res => res.json())
    .then(data => {
      setPlayerId(data.player_id)
      setPlayerName(data.player_name);
      setPlayerLinkVerified(data.player_link_verified);
      setPlayerLinkVerifiedId(data.id);
    });
  }

  const handleDelete = () => {
    setRemoveButtonText('Deleting claim...')
    fetch(`${API_HOST}/user_player_links/delete`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }),
      credentials: 'same-origin',
    })
    .then((res) => res.json())
    .then(data => {
      setPlayerLinkVerified(false);
      setPlayerId(null);
      setSubmitDisabled(false);
    })
  }

  return (
    <>
      <div className="m-1 p-4 border-pkl-500 border rounded-lg">
        {!playerId &&
          <div>
            <p className="font-bold mb-2">First time here?</p>
            <p className="mb-4">Start by checking if you have a player ID. Even if you are new to pklsmart, you may already have a player ID. pklsmart enables users to generate match data for all players in a match, so search your name to check if any match data has been created for games you participated in.
            </p>

            <Formik initialValues={{playerId: ''}} onSubmit={handleClaim}>
              {({ handleChange, values, setFieldValue }) => (
                <Form>
                  <div className="my-4">
                    <Autocomplete
                      options={
                        players.map(player => {
                          return {label: `${player.first_nm} ${player.last_nm}`, id: player.player_id}
                        })
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Full Name" />}
                      onChange={(e, value) => setFieldValue("playerId", value.id)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </div>
                  <button type="submit" disabled={submitDisabled} className="mt-2 bg-pkl-500 hover:bg-pkl-400 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-pkl-300 hover:bg-gray-50 inline-flex disabled:bg-gray-50 disabled:text-gray-500 text-lg items-center align-center disabled:pointer-events-none">Submit claim</button>
                </Form>
              )}
            </Formik>
          </div>}
          {(playerId && !playerLinkVerified) &&
            <p className="my-2 font-semibold text-gray-800">
              ✅ Your have successfully requested to claim pklsmart player: {playerName} ({playerId}). We will handle your request within 48 hours. Thank you for your patience.
              <button onClick={() => handleDelete(playerId)} className="mt-4 bg-red-400 hover:bg-red-500 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50 inline-flex">{removeButtonText}</button>
            </p>}
      </div>
    </>
  );
}

export default ClaimPlayer;
